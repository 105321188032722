import {Component} from "@angular/core";

@Component({
    selector: 'app-business-info',
    templateUrl: './business-info.component.html',
    styleUrls: ['./business-info.component.scss']
})

export class BusinessInfoComponent {

}