<section class="content">
    <div class="container-fluid ml-lg-3 ml-2">
        <div class="row">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-12 mt-3">
                    <div class="form-floating">
                        <select class="form-select" id="floatingSelect"
                                aria-label="Floating label select example" (change)="userFilter($event, 'fpoType')">
                            <option selected>Select FPO</option>
                            <option value="All">All</option>
                            <option *ngFor="let fpo of this.fpoList">{{ fpo }}</option>
                        </select>
                        <label for="floatingSelect">FPO Name</label>
                    </div>
                </div>
                <!--<div class="col-lg-3 col-md-6 col-12 mt-3">
                    <div class="form-floating">
                        <select class="form-select"
                                aria-label="Floating label select example" (change)="userFilter($event, 'date')">
                            <option selected disabled>Select Date</option>
                            <option value="All">All</option>
                            <option *ngFor="let date of this.date">{{ date }}</option>
                        </select>
                        <label for="floatingSelect">Date</label>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12 mt-3">
                    <div class="form-floating">
                        <select class="form-select"
                                aria-label="Floating label select example" (change)="userFilter($event, 'location')">
                            <option selected disabled>Select User Location</option>
                            <option value="All">All</option>
                            <option *ngFor="let location of this.userLocation" class="text-capitalize">{{ location }}</option>
                        </select>
                        <label for="floatingSelect">Location</label>
                    </div>
                </div>-->
                <div class="col-lg-6 col-md-6 col-12 mt-3"></div>
                <div class="col-lg-3 col-md-6 col-12 mt-3">
                    <div class="form-floating">
                        <input type="search" class="form-control" placeholder="search"
                               [(ngModel)]="searchText">
                        <label class="text-gray">Search</label>
                    </div>
                </div>
            </div>

            <div class="row mt-3 mb-3">
                <div class="col-lg-6 col-md-6 col-12 mb-2">
                    <div class="form-floating">
            <textarea
                    type="text"
                    class="form-control"
                    placeholder="Enter your message.."
                    [(ngModel)]="messageContent"
                    name="messageContent"
                    #messageContentRef="ngModel"
                    [ngClass]="{'is-invalid': messageContentRef.touched && messageContentRef.invalid,
                            'is-valid': messageContentRef.touched && messageContentRef.valid}" required></textarea>
                        <label class="font-weight-light">Enter Whatsapp Message</label>
                        <span *ngIf="messageContentRef.touched && messageContentRef.invalid" class="text-danger">
                Message content is required.
            </span>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12 mb-2">
                    <div class="form-floating">
                        <input
                                type="date"
                                class="form-control"
                                placeholder="Select Message Date"
                                [(ngModel)]="messageDate"
                                name="messageDate"
                                #messageDateRef="ngModel"
                                [ngClass]="{'is-invalid': messageDateRef.touched && messageDateRef.invalid,
                            'is-valid': messageDateRef.touched && messageDateRef.valid}" [min]="todayDate"
                                required>
                        <label class="font-weight-light">Select Date</label>
                        <span *ngIf="messageDateRef.touched && messageDateRef.invalid" class="text-danger">
                Message date is required.
            </span>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12 mb-2 text-end">
                    <button class="btn btn-success mt-2 p-2" (click)="sendGroupWhatsappMessage()">Send Whatsapp
                        <i class="fab fa-whatsapp"></i>
                    </button>
                </div>
            </div>

            <div class="container-fluid mt-3">
                <div class="table-responsive table-style table-bordered">
                    <table class="table table-green table-head-fixed">
                        <thead class="">
                        <tr>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Phone Number</th>
                            <th>WhatsApp Number</th>
                            <th>Trail End Date</th>
                            <th>Send</th>
                        </tr>
                        </thead>
                        <tbody class="text-center" *ngIf="this.tableUserDetailsList.length > 0; else noDataFound">
                        <tr *ngFor="let user of this.tableUserDetailsList | filter: searchText; let i = index;">
                            <td>{{ i + 1 }}</td>
                            <td>{{ user.name || '-' }}</td>
                            <td>{{ user.phoneNumber || '-' }}</td>
                            <td>{{ user.whatsappNumber || '-' }}</td>
                            <td>{{ user.trialEndDate | date: 'dd-MM-yyyy' || '-' }}</td>
                            <td>
                                <img class="img-fluid" src="assets/img/whatsapp.png" type="button" mattooltip="Click to Send" (click)="sendWhatsappMessage(user)">
                            </td>
                        </tr>
                        </tbody>
                        <ng-template #noDataFound>
                            <tr>
                                <td colspan="12" class="text-center text-danger">Data Not Found</td>
                            </tr>
                        </ng-template>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" type="jelly-box">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>

<div class="modal" id="editProductModal" tabindex="-1" role="dialog" aria-labelledby="editProductModal"
     aria-hidden="true" [style.display]="messageModal ? 'block' : 'none'"
     style="background-color: rgba(87,86,86,0.64)">
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Send Whatsapp Message</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="messageModal = false">
                    <span aria-hidden="true" class="h3">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div class="row mt-3 mb-3">
                    <div class="col-12">
                        <div class="form-floating">
            <textarea
                    type="text"
                    class="form-control"
                    placeholder="Enter your message.."
                    [(ngModel)]="userMessageContent"
                    name="userMessageContent"
                    #userMessageContentRef="ngModel"
                    [ngClass]="{'is-invalid': userMessageContentRef.touched && userMessageContentRef.invalid,
                            'is-valid': userMessageContentRef.touched && userMessageContentRef.valid}" required></textarea>
                            <label class="font-weight-light">Enter Whatsapp Message</label>
                            <span *ngIf="userMessageContentRef.touched && userMessageContentRef.invalid" class="text-danger">
                Message content is required.
            </span>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-center align-items-center">
                    <button class="btn btn-danger" (click)="cancelMessage()">Cancel</button>
                    <button class="btn btn-success ml-3" (click)="sendMessageByUser()">Send</button>
                </div>
            </div>
        </div>
    </div>
</div>