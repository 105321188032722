import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private userRegId: number | null = null;

    constructor() {}

    // Set the userRegId
    setUserRegId(id: number): void {
        this.userRegId = id;
        localStorage.setItem('userRegId', id.toString());
        localStorage.setItem('vendorRegId', id.toString());
    }

    // Get the userRegId, first checking the service, then localStorage
    getUserRegId(): number | null {
        if (this.userRegId) {
            return this.userRegId;
        }
        const storedId = localStorage.getItem('userRegId');
        if (storedId) {
            this.userRegId = Number(storedId);
            return this.userRegId;
        }
        return null;
    }

    // Clear the userRegId from service and localStorage
    clearUserRegId(): void {
        this.userRegId = null;
        localStorage.removeItem('userRegId');
    }
}
