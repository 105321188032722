<section class="content">
    <div class="container-fluid">
        <div class="row" *ngIf="showForms">
            <form [formGroup]="newBusinessDetails">
                <div class="row mt-3">

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Business Name"
                                formControlName="businessName"
                                [ngClass]="{'is-invalid': newBusinessDetails.get('businessName')?.invalid && newBusinessDetails.get('businessName')?.touched,
                    'is-valid': newBusinessDetails.get('businessName')?.valid && newBusinessDetails.get('businessName')?.touched}">
                            <label class="text-gray font-weight-lighter">Business Name</label>
                        </div>
                        <span class="text-danger fw-blod"
                            *ngIf="allTypeOfError.businessName">{{allTypeOfError.businessName}}</span>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2" *ngIf="this.onShowServiceProvider">
                        <div class="form-floating">
                            <select class="form-select" id="floatingSelect" formControlName="typeOfService"
                                aria-label="Floating label select example"
                                [ngClass]="{'is-invalid': newBusinessDetails.get('typeOfService')?.invalid && newBusinessDetails.get('typeOfService')?.touched,
                    'is-valid': newBusinessDetails.get('typeOfService')?.valid && newBusinessDetails.get('typeOfService')?.touched}">
                                <option selected hidden value="">Type of Service</option>
                                <option value="Pest Control">Pest Control</option>
                                <option value="Soil Testing">Soil Testing</option>
                                <option value="Equipment Rental">Equipment Rental</option>
                                <option value="Transportation">Transportation</option>
                                <option value="Irrigation Services">Irrigation Services,</option>
                                <option value="Consultancy">Consultancy</option>
                                <option value="Others">Others</option>
                            </select>
                            <label for="floatingSelect">Type of Service</label>
                        </div>
                        <span class="text-danger fw-blod"
                            *ngIf="allTypeOfError.typeOfService">{{allTypeOfError.typeOfService}}</span>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2" *ngIf="this.onShowServiceProvider">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Service License Number"
                                formControlName="serviceLicenseNumber"
                                [ngClass]="{'is-invalid': newBusinessDetails.get('serviceLicenseNumber')?.invalid && newBusinessDetails.get('serviceLicenseNumber')?.touched,
                    'is-valid': newBusinessDetails.get('serviceLicenseNumber')?.valid && newBusinessDetails.get('serviceLicenseNumber')?.touched}">
                            <label class="text-gray font-weight-lighter">Service License Number </label>
                        </div>
                        <span class="text-danger fw-blod"
                            *ngIf="allTypeOfError.serviceLicenseNumber">{{allTypeOfError.serviceLicenseNumber}}</span>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2" *ngIf="this.onShowCommodityProcessor || this.onShowFoodProcessor
                    || this.onShowFramerInput">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Business License Number"
                                formControlName="businessLicenseNumber"
                                [ngClass]="{'is-invalid': newBusinessDetails.get('businessLicenseNumber')?.invalid && newBusinessDetails.get('businessLicenseNumber')?.touched,
                    'is-valid': newBusinessDetails.get('businessLicenseNumber')?.valid && newBusinessDetails.get('businessLicenseNumber')?.touched}">
                            <label class="text-gray font-weight-lighter">Business License Number </label>
                        </div>
                        <span class="text-danger fw-blod"
                            *ngIf="allTypeOfError.businessLicenseNumber">{{allTypeOfError.businessLicenseNumber}}</span>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2" *ngIf="this.onShowTrader || this.onShowDistributor">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Trader License Number"
                                formControlName="tradeLicenseNumber"
                                [ngClass]="{'is-invalid': newBusinessDetails.get('tradeLicenseNumber')?.invalid && newBusinessDetails.get('tradeLicenseNumber')?.touched,
                    'is-valid': newBusinessDetails.get('tradeLicenseNumber')?.valid && newBusinessDetails.get('tradeLicenseNumber')?.touched}">
                            <label class="text-gray font-weight-lighter">Trader License Number</label>
                        </div>
                        <span class="text-danger fw-blod"
                            *ngIf="allTypeOfError.tradeLicenseNumber">{{allTypeOfError.tradeLicenseNumber}}</span>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Contact Number" maxlength="10"
                                (keypress)="OnlyNumbers($event)" formControlName="contactNumber"
                                [ngClass]="{'is-invalid': newBusinessDetails.get('contactNumber')?.invalid && newBusinessDetails.get('contactNumber')?.touched,
                    'is-valid': newBusinessDetails.get('contactNumber')?.valid && newBusinessDetails.get('contactNumber')?.touched}">
                            <label class="text-gray font-weight-lighter">Contact Number</label>
                        </div>
                        <span class="text-danger fw-blod"
                            *ngIf="allTypeOfError.contactNumber">{{allTypeOfError.contactNumber}}</span>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="GST Number" formControlName="gstNo"
                                [ngClass]="{'is-invalid': newBusinessDetails.get('gstNo')?.invalid && newBusinessDetails.get('gstNo')?.touched,
                    'is-valid': newBusinessDetails.get('gstNo')?.valid && newBusinessDetails.get('gstNo')?.touched}">
                            <label class="text-gray font-weight-lighter">GST Number</label>
                        </div>
                        <span class="text-danger fw-blod" *ngIf="allTypeOfError.gstNo">{{allTypeOfError.gstNo}}</span>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2"
                        *ngIf="this.onShowFramerInput || this.onShowServiceProvider">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Service Reach/Area Covered"
                                formControlName="serviceReach"
                                [ngClass]="{'is-invalid': newBusinessDetails.get('serviceReach')?.invalid && newBusinessDetails.get('serviceReach')?.touched,
                    'is-valid': newBusinessDetails.get('serviceReach')?.valid && newBusinessDetails.get('serviceReach')?.touched}">
                            <label class="text-gray font-weight-lighter">Service Reach/Area Covered</label>
                        </div>
                        <span class="text-danger fw-blod"
                            *ngIf="allTypeOfError.serviceReach">{{allTypeOfError.serviceReach}}</span>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2" *ngIf="this.onShowTrader || this.onShowDistributor 
                    || this.onShowFoodProcessor || this.onShowCommodityProcessor">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Source Regions"
                                formControlName="sourceRegions"
                                [ngClass]="{'is-invalid': newBusinessDetails.get('sourceRegions')?.invalid && newBusinessDetails.get('sourceRegions')?.touched,
                    'is-valid': newBusinessDetails.get('sourceRegions')?.valid && newBusinessDetails.get('sourceRegions')?.touched}">
                            <label class="text-gray font-weight-lighter">Source Regions</label>
                        </div>
                        <span class="text-danger fw-blod"
                            *ngIf="allTypeOfError.sourceRegions">{{allTypeOfError.sourceRegions}}</span>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2" *ngIf="this.onShowTrader">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Distribution Regions"
                                formControlName="distributionRegions"
                                [ngClass]="{'is-invalid': newBusinessDetails.get('distributionRegions')?.invalid && newBusinessDetails.get('distributionRegions')?.touched,
                    'is-valid': newBusinessDetails.get('distributionRegions')?.valid && newBusinessDetails.get('distributionRegions')?.touched}">
                            <label class="text-gray font-weight-lighter">Distribution Regions</label>
                        </div>
                        <span class="text-danger fw-blod"
                            *ngIf="allTypeOfError.distributionRegions">{{allTypeOfError.distributionRegions}}</span>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2" *ngIf="this.onShowServiceProvider">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Availability"
                                formControlName="availability"
                                [ngClass]="{'is-invalid': newBusinessDetails.get('availability')?.invalid && newBusinessDetails.get('availability')?.touched,
                    'is-valid': newBusinessDetails.get('availability')?.valid && newBusinessDetails.get('availability')?.touched}">
                            <label class="text-gray font-weight-lighter">Availability</label>
                        </div>
                        <span class="text-danger fw-blod"
                            *ngIf="allTypeOfError.availability">{{allTypeOfError.availability}}</span>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2" *ngIf="this.onShowServiceProvider">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Pricing Model"
                                formControlName="pricingModel"
                                [ngClass]="{'is-invalid': newBusinessDetails.get('pricingModel')?.invalid && newBusinessDetails.get('pricingModel')?.touched,
                    'is-valid': newBusinessDetails.get('pricingModel')?.valid && newBusinessDetails.get('pricingModel')?.touched}">
                            <label class="text-gray font-weight-lighter">Pricing Model</label>
                        </div>
                        <span class="text-danger fw-blod"
                            *ngIf="allTypeOfError.pricingModel">{{allTypeOfError.pricingModel}}</span>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="tel" class="form-control" placeholder="Latitude" formControlName="latitude"
                                [ngClass]="{'is-invalid': newBusinessDetails.get('latitude')?.invalid && newBusinessDetails.get('latitude')?.touched,
                    'is-valid': newBusinessDetails.get('latitude')?.valid && newBusinessDetails.get('latitude')?.touched}">
                            <label class="text-gray font-weight-lighter">Latitude</label>
                        </div>
                        <span class="text-danger fw-blod"
                            *ngIf="allTypeOfError.latitude">{{allTypeOfError.latitude}}</span>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="tel" class="form-control" placeholder="Longitude" formControlName="longitude"
                                [ngClass]="{'is-invalid': newBusinessDetails.get('longitude')?.invalid && newBusinessDetails.get('longitude')?.touched,
                    'is-valid': newBusinessDetails.get('longitude')?.valid && newBusinessDetails.get('longitude')?.touched}">
                            <label class="text-gray font-weight-lighter">Longitude</label>
                        </div>
                        <span class="text-danger fw-blod"
                            *ngIf="allTypeOfError.longitude">{{allTypeOfError.longitude}}</span>
                    </div>
                    <!-- Display Selected Images in Card Format for Certifications -->
                    <!-- <div class="col-12 mb-2" *ngIf="certificationsFiles.length > 0">
                        <div class="row">
                            <div class="col-md-3" *ngFor="let file of certificationsFiles; let i = index">
                                <div class="card">
                                    <img [src]="file.documentPath" *ngIf="file.documentExtension !== 'pdf'"
                                        class="card-img-top" alt="Selected Image">
                                    <div class="card-body text-center">
                                        <button type="button" class="btn btn-danger btn-sm"
                                            (click)="removeFile(i, 'Certification Documents')">Remove</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <textarea type="file" class="form-control" placeholder="Description"
                                formControlName="description"
                                [ngClass]="{'is-invalid': newBusinessDetails.get('description')?.invalid && newBusinessDetails.get('description')?.touched,
                    'is-valid': newBusinessDetails.get('description')?.valid && newBusinessDetails.get('description')?.touched}"></textarea>
                            <label class="text-gray font-weight-lighter">Description</label>
                        </div>
                        <span class="text-danger fw-blod"
                            *ngIf="allTypeOfError.description">{{allTypeOfError.description}}</span>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="file" class="form-control" placeholder="Certifications & Memberships"
                                (change)="onFileSelected($event, 'Certifications')" accept="capture=camera,image/*">
                            <label class="text-gray font-weight-lighter">Certifications & Memberships</label>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="file" class="form-control" placeholder="Business Documents upload"
                                (change)="onFileSelected($event, 'Business Documents')" accept="capture=camera,image/*">
                            <label class="text-gray font-weight-lighter">Business Documents upload</label>
                        </div>
                    </div>

                    <!-- Display Selected Images in Card Format for Business Documents -->
                    <div class="col-12 mb-2" *ngIf="businessDocumentsFiles.length > 0">
                        <div class="row">
                            <div class="col-md-3 mb-2" *ngFor="let file of businessDocumentsFiles; let i = index">
                                <div class="card h-100">

                                    <a *ngIf="file.documentExtension !== 'pdf' && file.businessDocumentId == 0"
                                        [href]="file.documentPath" target="_blank" class="text-center mt-2">
                                        <img mat-card-lg-image [src]="file.documentPath" class="img-fluid"
                                            accept="capture=camera,image/*">
                                    </a>

                                    <a *ngIf="file.documentExtension !== 'pdf' && file.businessDocumentId != 0" class="text-center mt-2"
                                        [href]="this.endPoint + '/api/business-documents/get/' + file.businessDocumentId"
                                        target="_blank">
                                        <img mat-card-lg-image
                                            [src]="this.endPoint + '/api/business-documents/get/' + file.businessDocumentId"
                                            class="img-fluid" accept="capture=camera,image/*">
                                    </a>


                                    <div class="card-body text-center" *ngIf="file.businessDocumentId == 0">
                                        <p class="text-danger fw-bold">{{ file.documentDescription }}</p>
                                        <button type="button" class="btn btn-danger btn-sm"
                                            (click)="removeFile(i, file.documentDescription)">Remove</button>
                                    </div>

                                    <div class="card-body text-center" *ngIf="file.businessDocumentId != 0">
                                        <p class="text-danger fw-bold">{{ file.documentDescription }}</p>
                                        <button type="button" class="btn btn-danger btn-sm"
                                            (click)="deleteBusinessFile(file.businessDocumentId)">Remove</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </div>

        <div class="row" *ngIf="this.onShowFPO">
            <form [formGroup]="newFarmerDetails">
                <div class="py-2"></div>
                <h4 class="text-bold">Farms Details</h4>
                <div class="row mt-3">
                    <div class="col-lg-3 col-md-4 col-12 mb-2" *ngIf="enRollType === 'Farmers'">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Farm Size" formControlName="farmSize"
                                [ngClass]="{'is-invalid': newFarmerDetails.get('farmSize')?.invalid && newFarmerDetails.get('farmSize')?.touched,
                    'is-valid': newFarmerDetails.get('farmSize')?.valid && newFarmerDetails.get('farmSize')?.touched}">
                            <label class="text-gray font-weight-lighter">Farm Size</label>
                        </div>
                        <span class="text-danger fw-blod"
                            *ngIf="this.allTypeOfError.farmSize">{{this.allTypeOfError.farmSize}}</span>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2" *ngIf="enRollType === 'Farmers'">
                        <div class="form-floating">
                            <select class="form-select" aria-label="Floating label select example"
                                formControlName="primaryCropsGrown"
                                [ngClass]="{'is-invalid': newFarmerDetails.get('primaryCropsGrown')?.invalid && newFarmerDetails.get('primaryCropsGrown')?.touched,
                    'is-valid': newFarmerDetails.get('primaryCropsGrown')?.valid && newFarmerDetails.get('primaryCropsGrown')?.touched}">
                                <option selected hidden value="">Select Primary Crops</option>
                                <option *ngFor="let commodity of commodityDetails" [value]="commodity.commodityName">
                                    {{commodity.commodityName}}</option>
                            </select>
                            <label for="floatingSelect" class="text-gray font-weight-lighter">Primary Crops</label>
                        </div>
                        <span class="text-danger fw-blod"
                            *ngIf="allTypeOfError.primaryCropsGrown">{{allTypeOfError.primaryCropsGrown}}</span>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2" *ngIf="enRollType === 'FPO'">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Number of Members"
                                formControlName="noOfMembers"
                                [ngClass]="{'is-invalid': newFarmerDetails.get('noOfMembers')?.invalid && newFarmerDetails.get('noOfMembers')?.touched,
                    'is-valid': newFarmerDetails.get('noOfMembers')?.valid && newFarmerDetails.get('noOfMembers')?.touched}">
                            <label class="text-gray font-weight-lighter">Numbers of Members</label>
                        </div>
                        <span class="text-danger fw-blod"
                            *ngIf="allTypeOfError.noOfMembers">{{allTypeOfError.noOfMembers}}</span>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2" *ngIf="enRollType === 'FPO'">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="FPO Registration Number"
                                formControlName="fpoRegistrationNumber"
                                [ngClass]="{'is-invalid': newFarmerDetails.get('fpoRegistrationNumber')?.invalid && newFarmerDetails.get('fpoRegistrationNumber')?.touched,
                    'is-valid': newFarmerDetails.get('fpoRegistrationNumber')?.valid && newFarmerDetails.get('fpoRegistrationNumber')?.touched}">
                            <label class="text-gray font-weight-lighter">FPO Registration Number</label>
                        </div>
                        <span class="text-danger fw-blod"
                            *ngIf="allTypeOfError.fpoRegistrationNumber">{{allTypeOfError.fpoRegistrationNumber}}</span>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Contact Number" maxlength="10"
                                (keypress)="OnlyNumbers($event)" formControlName="contactNumber"
                                [ngClass]="{'is-invalid': newFarmerDetails.get('contactNumber')?.invalid && newFarmerDetails.get('contactNumber')?.touched,
                    'is-valid': newFarmerDetails.get('contactNumber')?.valid && newFarmerDetails.get('contactNumber')?.touched}">
                            <label class="text-gray font-weight-lighter">Contact Number</label>
                        </div>
                        <span class="text-danger fw-blod"
                            *ngIf="allTypeOfError.contactNumber">{{allTypeOfError.contactNumber}}</span>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Latitude" formControlName="latitude"
                                [ngClass]="{'is-invalid': newFarmerDetails.get('latitude')?.invalid && newFarmerDetails.get('latitude')?.touched,
                    'is-valid': newFarmerDetails.get('latitude')?.valid && newFarmerDetails.get('latitude')?.touched}">
                            <label class="text-gray font-weight-lighter">Latitude</label>
                        </div>
                        <span class="text-danger fw-blod"
                            *ngIf="allTypeOfError.latitude">{{allTypeOfError.latitude}}</span>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Longitude" formControlName="longitude"
                                [ngClass]="{'is-invalid': newFarmerDetails.get('longitude')?.invalid && newFarmerDetails.get('longitude')?.touched,
                    'is-valid': newFarmerDetails.get('longitude')?.valid && newFarmerDetails.get('longitude')?.touched}">
                            <label class="text-gray font-weight-lighter">Longitude</label>
                        </div>
                        <span class="text-danger fw-blod"
                            *ngIf="allTypeOfError.longitude">{{allTypeOfError.longitude}}</span>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mb-2" *ngIf="this.enRollType == 'Farmers'">
                        <div class="form-floating">
                            <select class="form-select" id="floatingSelect" formControlName="fpoName"
                                aria-label="Floating label select example" (change)="onShowFPODetails($event)"
                                [ngClass]="{'is-invalid': newFarmerDetails.get('fpoName')?.invalid && newFarmerDetails.get('fpoName')?.touched,
                    'is-valid': newFarmerDetails.get('fpoName')?.valid && newFarmerDetails.get('fpoName')?.touched}">
                                <option selected hidden value="">Select FPO Name</option>
                                <option *ngFor="let name of this.fpoDetails" value="{{name.name}}">
                                    {{name.name}}</option>
                            </select>
                            <label for="floatingSelect">Select FPO Name</label>
                        </div>
                        <span class="text-danger fw-blod"
                            *ngIf="allTypeOfError.fpoName">{{allTypeOfError.fpoName}}</span>
                    </div>
                </div>
            </form>
        </div>


        <!-- Address information -->
        <form *ngIf="this.showForms || this.onShowFPO" [formGroup]="addressDetailsForms">
            <hr>
            <h4 class="text-bold">Address Details</h4>
            <div class="row">
                <div class="col-lg-3 col-md-4 col-12 mb-2">
                    <div class="form-floating">
                        <input type="text" class="form-control" placeholder="village / Town" formControlName="village"
                            [ngClass]="{'is-invalid': addressDetailsForms.get('village')?.invalid && addressDetailsForms.get('village')?.touched,
                    'is-valid': addressDetailsForms.get('village')?.valid && addressDetailsForms.get('village')?.touched}">
                        <label class="text-gray font-weight-lighter">village / Town</label>
                    </div>
                    <span class="text-danger fw-blod" *ngIf="allTypeOfError.village">{{allTypeOfError.village}}</span>
                </div>

                <div class="col-lg-3 col-md-4 col-12 mb-2">
                    <div class="form-floating">
                        <!-- <input type="text" class="form-control" placeholder="City" formControlName="city">
                        <label class="text-gray font-weight-lighter">City</label> -->
                        <select class="form-select" aria-label="Floating label select example" formControlName="city"
                            [ngClass]="{'is-invalid': addressDetailsForms.get('city')?.invalid && addressDetailsForms.get('city')?.touched,
                        'is-valid': addressDetailsForms.get('city')?.valid && addressDetailsForms.get('city')?.touched}">
                            <option selected hidden value="">Select City</option>
                            <option *ngFor="let city of districtsList" [value]="city">{{city}}</option>
                        </select>
                        <label for="floatingSelect" class="text-gray font-weight-lighter">City<span
                                class="text-danger">*</span></label>
                    </div>
                    <span class="text-danger fw-blod" *ngIf="allTypeOfError.city">{{allTypeOfError.city}}</span>
                </div>

                <div class="col-lg-3 col-md-4 col-12 mb-2">
                    <div class="form-floating">
                        <select class="form-select" aria-label="Floating label select example" formControlName="state"
                            [ngClass]="{'is-invalid': addressDetailsForms.get('state')?.invalid && addressDetailsForms.get('state')?.touched,
                        'is-valid': addressDetailsForms.get('state')?.valid && addressDetailsForms.get('state')?.touched}">
                            <option selected hidden value="">Select State</option>
                            <option *ngFor="let state of stateList" [value]="state">{{state}}</option>
                        </select>
                        <label class="text-gray font-weight-lighter">State</label>
                    </div>
                    <span class="text-danger fw-blod" *ngIf="allTypeOfError.state">{{allTypeOfError.state}}</span>
                </div>

                <div class="col-lg-3 col-md-4 col-12 mb-2">
                    <div class="form-floating">
                        <input type="text" class="form-control" placeholder="Pin Code" maxlength="6"
                            (keypress)="OnlyNumbers($event)" formControlName="pinCode"
                            [ngClass]="{'is-invalid': addressDetailsForms.get('pinCode')?.invalid && addressDetailsForms.get('pinCode')?.touched,
                    'is-valid': addressDetailsForms.get('pinCode')?.valid && addressDetailsForms.get('pinCode')?.touched}">
                        <label class="text-gray font-weight-lighter">Pin Code</label>
                    </div>
                    <span class="text-danger fw-blod" *ngIf="allTypeOfError.pinCode">{{allTypeOfError.pinCode}}</span>
                </div>
            </div>
        </form>

        <!-- Bank Details -->

        <form *ngIf="this.showForms || this.onShowFPO" [formGroup]="bankDetailsForms">
            <hr>
            <h4 class="text-bold">Bank Details</h4>
            <div class="row">
                <div class="col-lg-3 col-md-4 col-12 mb-2">
                    <div class="form-floating">
                        <select class="form-select" aria-label="Floating label select example"
                            formControlName="bankName" [ngClass]="{'is-invalid': bankDetailsForms.get('bankName')?.invalid && bankDetailsForms.get('bankName')?.touched,
                    'is-valid': bankDetailsForms.get('bankName')?.valid && bankDetailsForms.get('bankName')?.touched}">
                            <option selected hidden value="">Select Bank</option>
                            <option *ngFor="let bank of bankNames" value="{{bank}}">{{bank}}</option>
                        </select>
                        <label class="text-gray font-weight-lighter">Bank Name</label>
                    </div>
                    <span class="text-danger fw-blod" *ngIf="allTypeOfError.bankName">{{allTypeOfError.bankName}}</span>
                </div>

                <div class="col-lg-3 col-md-4 col-12 mb-2">
                    <div class="form-floating">
                        <input type="text" class="form-control" placeholder="Bank Holder Name"
                            formControlName="accountHolderName"
                            [ngClass]="{'is-invalid': bankDetailsForms.get('accountHolderName')?.invalid && bankDetailsForms.get('accountHolderName')?.touched,
                    'is-valid': bankDetailsForms.get('accountHolderName')?.valid && bankDetailsForms.get('accountHolderName')?.touched}">
                        <label class="text-gray font-weight-lighter">Bank Holder Name</label>
                    </div>
                    <span class="text-danger fw-blod"
                        *ngIf="allTypeOfError.accountHolderName">{{allTypeOfError.accountHolderName}}</span>
                </div>

                <div class="col-lg-3 col-md-4 col-12 mb-2">
                    <div class="form-floating">
                        <input type="text" class="form-control" placeholder="Bank Account Number"
                            (keypress)="OnlyNumbers($event)" formControlName="accountNumber"
                            [ngClass]="{'is-invalid': bankDetailsForms.get('accountNumber')?.invalid && bankDetailsForms.get('accountNumber')?.touched,
                    'is-valid': bankDetailsForms.get('accountNumber')?.valid && bankDetailsForms.get('accountNumber')?.touched}">
                        <label class="text-gray font-weight-lighter">Bank Account Number</label>
                    </div>
                    <span class="text-danger fw-blod"
                        *ngIf="allTypeOfError.accountNumber">{{allTypeOfError.accountNumber}}</span>
                </div>

                <div class="col-lg-3 col-md-4 col-12 mb-2">
                    <div class="form-floating">
                        <input type="text" class="form-control" placeholder="Bank IFSC Code" formControlName="ifscCode"
                            [ngClass]="{'is-invalid': bankDetailsForms.get('ifscCode')?.invalid && bankDetailsForms.get('ifscCode')?.touched,
                    'is-valid': bankDetailsForms.get('ifscCode')?.valid && bankDetailsForms.get('ifscCode')?.touched}">
                        <label class="text-gray font-weight-lighter">Bank IFSC Code</label>
                    </div>
                    <span class="text-danger fw-blod" *ngIf="allTypeOfError.ifscCode">{{allTypeOfError.ifscCode}}</span>
                </div>

                <div class="col-lg-3 col-md-4 col-12 mb-2">
                    <div class="form-floating">
                        <input type="text" class="form-control" placeholder="Bank Branch Name"
                            formControlName="branchName"
                            [ngClass]="{'is-invalid': bankDetailsForms.get('branchName')?.invalid && bankDetailsForms.get('branchName')?.touched,
                    'is-valid': bankDetailsForms.get('branchName')?.valid && bankDetailsForms.get('branchName')?.touched}">
                        <label class="text-gray font-weight-lighter">Bank Branch Name</label>
                    </div>
                    <span class="text-danger fw-blod"
                        *ngIf="allTypeOfError.branchName">{{allTypeOfError.branchName}}</span>
                </div>
            </div>
        </form>


        <div class="d-flex justify-content-center align-items-center align-content-between mt-4 mb-5" *ngIf="this.showForms || this.onShowFPO">
         <button type="button" class="btn btn-danger" (click)="cancel()">Cancel</button>
            <button type="button" class="btn btn-primary ml-4" (click)="onSubmit()"
                [ngClass]="{'update-class': isUpdate, 'save-class': !isUpdate}">{{ isUpdate ? 'Update' : 'Save'
                }}</button>
        </div>


    </div>
</section>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" type="jelly-box">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>