<section class="content">
    <div class="container-fluid">
        <div class="row mt-2 mb-5">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-3 mt-lg-3 mt-5">
                        <h3 class="mt-3 mb-3">Product Details <img class="img-fluid ml-3" alt="clear"
                                                                   src="assets/img/pen-circle.png" height="32"
                                                                   width="32" type="button" matTooltip="click to edit"
                                                                   (click)="editProductDetails()">
                        </h3>
                    </div>
                    <div class="col-lg-9">
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="row" *ngIf="isVerified != 'Approved'">
                                    <div class="col-md-3 col-lg-3 mt-3">
                                        <button class="btn-success-green btn-block"
                                                (click)="approveStatus('Approved', productId)">
                                            Approve
                                        </button>
                                    </div>
                                    <div class="col-md-3 col-lg-3 mt-3">
                                        <button class="btn-danger-red btn-block"
                                                (click)="approveStatus('Not Approved', productId)">
                                            Reject
                                        </button>
                                    </div>
                                    <div class="col-md-3 col-lg-3 mt-3">
                                        <button class="btn-secondary-light btn-block"
                                                (click)="approveStatus('Hold', productId)">Hold
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3 mt-3" *ngIf="isVerified == 'Approved'">
                                <div class="row">
                                    <div class="col-lg-9 text-end">
                                        <span class="badge-success-green btn-block p-3">Approved</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-8 mt-2">
                        <div class="row mt-2">
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Product Name</p>
                                    <span class="mt-0 fw-bold">{{productName || '-' }}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Uniqueness</p>
                                    <span class="mt-0 fw-bold">{{unique || '-' }}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Phone Number</p>
                                    <span class="mt-0 fw-bold">{{phoneNo || '-' }}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Description</p>
                                    <span class="mt-0 fw-bold">{{description || '-' }}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Location</p>
                                    <span class="mt-0 fw-bold">{{ this.location || '-' }}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2"
                                 *ngIf="this.userType == 'Farmers' || this.userType == 'FPO' ">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Farming Location</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.farmLocation || '-' }}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2"
                                 *ngIf="this.userType == 'Farmers' || this.userType == 'FPO' ">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Harvest Date</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.harvestDate | date: 'dd-MM-yyyy' }}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Price</p>
                                    <span class="mt-0 fw-bold">RS.{{price | currency:'INR':'' || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Tax</p>
                                    <span class="mt-0 fw-bold">{{tax || '-' }} %</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Net Price</p>
                                    <span class="mt-0 fw-bold">RS.{{netPrice | currency:'INR':'' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Quantity Value</p>
                                    <span class="mt-0 fw-bold">{{quantityValue}} {{quantityType}}</span>
                                </div>
                            </div>

                            <!-- <div class="col-12 col-lg-4 col-md-6 mt-2">
                                 <div class="bg-white-detail p-3">
                                     <p class="mb-0">Value</p>
                                     <span class="mt-0 fw-bold">{{quantityValue}}</span>
                                 </div>
                             </div>-->
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-md-12 p-3">
                        <div class="row mt-4 mb-3">
                            <p class="mb-0">Product Images</p>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-6 col-md-4 mt-2" *ngFor="let image of this.productImageList">
                                <img *ngIf="image?.imageId" src="{{endPoint}}/api/image-details/get/{{image.imageId}}"
                                     class="img-fluid" loading="lazy" (click)="openModal(image)">
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid col-lg-12 col-12">
                        <div class="row mt-4">
                            <div class="col-lg-12 col-12">
                                <table class="table bg-white-detail table-responsive-sm table-borderless">
                                    <thead>
                                    <th>Feature Name</th>
                                    <th>Feature Value</th>
                                    <th>Friendly Name</th>
                                    <th>Friendly Value</th>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let feature of this.featureList">
                                        <td>{{feature.featureName || '-'}}</td>
                                        <td>{{feature.featureValue || '-' }}</td>
                                        <td>{{feature.friendlyName || '-' }}</td>
                                        <td>{{feature.friendlyValue || '-' }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="modal" id="imageModal" tabindex="-1" role="dialog" aria-labelledby="imageModalLabel" aria-hidden="true"
     [style.display]="imageModalShow ? 'block' : 'none'" style="background-color: rgba(87,86,86,0.64)">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="imageModalLabel">Image Preview</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="imageModalShow = false">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 text-center">
                        <img [src]="selectedImageUrl" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="editProductModal" tabindex="-1" role="dialog" aria-labelledby="editProductModal"
     aria-hidden="true" [style.display]="editModalShow ? 'block' : 'none'"
     style="background-color: rgba(87,86,86,0.64)">
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Edit Product Detail</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="editModalShow = false">
                    <span aria-hidden="true" class="h3">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="productFormGroup" (submit)="OnSubmit()">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Name" formControlName="productName"
                                       [ngClass]="{'is-invalid': productFormGroup.get('productName')?.invalid && productFormGroup.get('productName')?.touched,
                                                            'is-valid': productFormGroup.get('productName')?.valid && productFormGroup.get('productName')?.touched}">
                                <label class="text-gray font-weight-lighter">Product Name <span
                                        class="text-red">*</span></label>
                            </div>
                            <span class="text-danger"
                                  *ngIf="productFormErrors.productName">{{ productFormErrors.productName }}</span>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Friendly Name"
                                       formControlName="friendlyName"
                                       [ngClass]="{'is-invalid': productFormGroup.get('friendlyName')?.invalid && productFormGroup.get('friendlyName')?.touched,
                                                            'is-valid': productFormGroup.get('friendlyName')?.valid && productFormGroup.get('friendlyName')?.touched}">
                                <label class="text-gray font-weight-lighter">Friendly Name</label>
                            </div>
                            <span class="text-danger"
                                  *ngIf="productFormErrors.friendlyName">{{ productFormErrors.friendlyName }}</span>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Description"
                                       formControlName="description"
                                       [ngClass]="{'is-invalid': productFormGroup.get('description')?.invalid && productFormGroup.get('description')?.touched,
                                                            'is-valid': productFormGroup.get('description')?.valid && productFormGroup.get('description')?.touched}">
                                <label class="text-gray font-weight-lighter">Description<span
                                        class="text-red">*</span></label>
                            </div>
                            <span class="text-danger"
                                  *ngIf="productFormErrors.description">{{ productFormErrors.description }}</span>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Friendly Description"
                                       formControlName="friendlyDescription">
                                <label class="text-gray font-weight-lighter">Friendly Description</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Discount"
                                       formControlName="discount">
                                <label class="text-gray font-weight-lighter">Discount</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Tax" formControlName="tax">
                                <label class="text-gray font-weight-lighter">Tax</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Gross Pric"
                                       formControlName="grossPrice">
                                <label class="text-gray font-weight-lighter">Gross Price</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Net Price"
                                       formControlName="netPrice">
                                <label class="text-gray font-weight-lighter">Net Price</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Location"
                                       formControlName="location">
                                <label class="text-gray font-weight-lighter">Location</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Phone Number"
                                       formControlName="phoneNumber" maxlength="10" minlength="10"
                                       [ngClass]="{'is-invalid': productFormGroup.get('phoneNumber')?.invalid && productFormGroup.get('phoneNumber')?.touched,
                                                            'is-valid': productFormGroup.get('phoneNumber')?.valid && productFormGroup.get('phoneNumber')?.touched}">
                                <label class="text-gray font-weight-lighter">Phone Number</label>
                            </div>
                            <span class="text-danger"
                                  *ngIf="productFormErrors.phoneNumber">{{ productFormErrors.phoneNumber }}</span>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Uniqueness"
                                       formControlName="uniqueness">
                                <label class="text-gray font-weight-lighter">Uniqueness</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Availability"
                                       formControlName="availability">
                                <label class="text-gray font-weight-lighter">Availability</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Quantity Type"
                                       formControlName="quantityType">
                                <label class="text-gray font-weight-lighter">Quantity Type</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <select class="form-select" id="floatingSelectCategory"
                                        aria-label="Floating label select example" formControlName="category">
                                    <option selected value="">Select a Category</option>
                                    <option *ngFor="let cat of this.categories" [value]="cat">{{ cat }}</option>
                                </select>
                                <label for="floatingSelectCategory">Select Category</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <select class="form-select" id="floatingSelect"
                                        aria-label="Floating label select example" formControlName="tags">
                                    <option selected value="">Select a tag</option>
                                    <option *ngFor="let tag of this.tags" [value]="tag">{{ tag }}</option>
                                </select>
                                <label for="floatingSelect">Select Tag</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <select class="form-select" id="floatingSelectStatus"
                                        aria-label="Floating label select example" formControlName="status">
                                    <option hidden selected value="">Select a Status</option>
                                    <option *ngFor="let status of this.availableStatus"
                                            [value]="status">{{ status }}</option>
                                </select>
                                <label for="floatingSelectStatus">Select Status</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <textarea type="text" class="form-control" placeholder="Marketing Description"
                                          formControlName="marketingDescription"></textarea>
                                <label class="text-gray font-weight-lighter">Marketing Description</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Packaging Type"
                                       formControlName="packagingType">
                                <label class="text-gray font-weight-lighter">Packaging Type</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Shelf Life"
                                       formControlName="shelfLife">
                                <label class="text-gray font-weight-lighter">Shelf Life</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Farming Practices"
                                       formControlName="farmingPractices">
                                <label class="text-gray font-weight-lighter">Farming Practices</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Farm Location"
                                       formControlName="farmLocation">
                                <label class="text-gray font-weight-lighter">Farm Location</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Storage Condition"
                                       formControlName="storageCondition">
                                <label class="text-gray font-weight-lighter">Storage Condition</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Batch Number"
                                       formControlName="batchNumber">
                                <label class="text-gray font-weight-lighter">Batch Number</label>
                            </div>
                        </div>

                        <div class="row mt-3 mb-3">
                            <div class="col-12 text-center d-flex justify-content-center align-content-between">
                                <button type="button" class="btn btn-danger ml-2" (click)="cancelProductUpdate()">
                                    Cancel
                                </button>
                                <button type="submit" class="btn btn-success ml-5">Update</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" type="jelly-box">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>
