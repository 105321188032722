<section class="vh-100 bg-opacity-10 login">
    <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                <div class="card shadow p-lg-5 p-5">
                    <div class="text-center">
                        <!--<h3 class="fw-bolder mt-2 mb-2">E-Shed</h3>-->
                        <img class="logo-image" src="assets/img/eshed-logo.png" alt="logo">
                    </div>
                    <div class="card-body">
                        <h6 class="text-bold mb-1 text-center" *ngIf="buttonShow">Enter your mobile number & MPIN to login</h6>
                        <!--<p class="fw-light mt-3 mb-3 text-center span" *ngIf="buttonShow">We will send you a confirmation code</p>
                        <div class="text-center" *ngIf="openOTPModal">
                            <span class="span">A verification OTP has been sent to {{phoneNumber}}</span>
                            <h6 class="fw-bold text-center mt-3">Enter your OTP</h6>
                        </div>-->
                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                            <div class="row mt-2 p-1">
                                <span class="badge error-gradient-red text-start" *ngIf="this.errorMessage"><i class="fa fa-times-circle"></i><span class="ml-2">{{this.errorMessage}}</span></span>
                                <div class="col-lg-3 col-3 col-md-3 mt-3">
                                    <div class="form-floating">
                                        <select class="form-select p-lg-0 p-2 m-lg-0 text-center fs-4 country-flags" id="countryCode"
                                                aria-label="Floating label select example"
                                                [ngClass]="{'is-invalid': buttonClicked && loginForm.get('countryCode')?.invalid, 'is-valid': loginForm.get('countryCode')?.valid}"
                                                formControlName="countryCode">
                                            <option *ngFor="let option of options" [value]="option.value" [selected]="option.value == '91' && option.flag === '🇮🇳'">{{option.flag}}</option>
                                        </select>
                                    </div>
                                    <span *ngIf="loginErrors.countryCode"
                                          class="text-danger span ml-2">{{loginErrors.countryCode}}</span>
                                </div>
                                <div class="col-lg-9 col-9 col-md-9 mb-3 p-0 m-0">
                                    <div class="form-floating mt-3">
                                        <input type="text" class="form-control" id="phoneNumber"
                                               placeholder="Enter your registered phone number"
                                               formControlName="phoneNumber" (keypress)="OnlyNumbers($event)"
                                               minlength="10" maxlength="10"
                                               [ngClass]="{'is-invalid': buttonClicked && loginForm.get('phoneNumber')?.invalid, 'is-valid': loginForm.get('phoneNumber')?.valid}">
                                        <label class="text-gray">Phone Number</label>
                                    </div>
                                    <span *ngIf="loginErrors.phoneNumber" class="text-danger span ml-2">{{loginErrors.phoneNumber}}</span>
                                </div>
                                <div class="col-lg-12 col-12 col-md-12 mb-3 p-0 m-0">
                                    <div class="form-floating mt-3">
                                        <input type="text" class="form-control" id="mpin"
                                               placeholder="Enter your MPIN"
                                               formControlName="mpin" (keypress)="OnlyNumbers($event)"
                                               minlength="4" maxlength="4"
                                               [ngClass]="{'is-invalid': buttonClicked && loginForm.get('mpin')?.invalid, 'is-valid': loginForm.get('mpin')?.valid}">
                                        <label class="text-gray">MPIN</label>
                                    </div>
                                    <span *ngIf="loginErrors.mpin" class="text-danger span ml-2">{{loginErrors.mpin}}</span>
                                </div>

                                <div class="col-lg-12 p-0 m-0 text-center">
                                    <button type="submit" class="btn btn-success btn-block text-bold p-3 mt-3 mb-3">Login</button>
                                </div>
                            </div>
                        </form>

                       <!-- <div class="col-lg-12 col-12 col-md-12 nt-3 mb-3" *ngIf="openOTPModal">
                            &lt;!&ndash; <div class="text-center"><span
                                     class="span">A verification OTP code has been sent to {{phoneNumber}}</span></div>&ndash;&gt;
                            <div id="emailOtp" class="inputs d-flex flex-row justify-content-center mt-2"
                                 (keypress)="OnlyNumbers($event)"><input autofocus
                                    class="m-2 text-center  otpInput" type="tel" #txt1 maxlength="1" [(ngModel)]="firstValue"
                                    (keyup)="moveFocus($event, '', txt1, txt2)"/> <input
                                    class="m-2 text-center  otpInput" type="tel" #txt2 maxlength="1" [(ngModel)]="secondValue"
                                    (keyup)="moveFocus($event, txt1, txt2, txt3)"/> <input
                                    class="m-2 text-center  otpInput" type="tel" #txt3 maxlength="1" [(ngModel)]="thirdValue"
                                    (keyup)="moveFocus($event, txt2, txt3, txt4)"/> <input
                                    class="m-2 text-center  otpInput" type="tel" #txt4 maxlength="1" [(ngModel)]="fourthValue"
                                    (keyup)="moveFocus($event, txt3, txt4, '')"/></div>
                            <div class="d-flex justify-content-center col-md-12">
                                <button type="button" class="btn btn-success btn-block p-3 mt-3 mb-3" (click)="verifyOtp();">Login
                                </button>
                            </div>
                        </div>-->

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" type="jelly-box">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>
