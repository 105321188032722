import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import {UiKitComponent} from "@/UI-kit/ui-kit.component";
import {UserManagementComponent} from "@pages/user-management/user-management.component";
import {VerifyProfileComponent} from "@pages/verify-profile/verify-profile.component";
import {ProductComponent} from "@pages/product/product.component";
import {PostComponent} from "@pages/post/post.component";
import {PostDetailsComponent} from "@pages/post-detail-view/post-detail.component";
import {ProductDetailComponent} from "@pages/product-detail/product-detail.component";
import {OrdersComponent} from "@pages/orders/orders.component";
import {ProductOrdersComponent} from "@pages/orders/product-orders/product-orders.component";
import {PostOrdersComponent} from "@pages/orders/post-orders/post-orders.component";
import {PaymentComponent} from "@pages/payment/payment.component";
import {EnquiryComponent} from "@pages/enquiry/enquiry.component";
import {UserRegistrationComponent} from "@pages/registration/user-registration/user-registration.component";
import {BusinessInfoComponent} from "@pages/registration/business-info/business-info.component";
import { BusinessDetailsComponent } from '@pages/business/business-details/business-details.component';
import {WhatsappComponent} from "@pages/whatsapp/whatsapp.component";

const routes: Routes = [
    {
        path: 'main',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        canLoad: [AuthGuard],
        children: [
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'blank',
                component: BlankComponent
            },
            {
                path: 'sub-menu-1',
                component: SubMenuComponent
            },
            {
                path: 'sub-menu-2',
                component: BlankComponent
            },
            {
                path: 'ui-kit',
                component: UiKitComponent
            },
            {
                path: 'dash',
                component: DashboardComponent
            },
            {
                path: 'user-management',
                component: UserManagementComponent
            },
            {
                path: 'verify-profile',
                component: VerifyProfileComponent
            },
            {
                path: 'product',
                component: ProductComponent
            },
            {
                path: 'product-detail',
                component: ProductDetailComponent
            },
            {
                path: 'post',
                component: PostComponent
            },
            {
                path: 'post-detail-view',
                component: PostDetailsComponent
            },
            {
                path: 'orders',
                component: OrdersComponent
            },
            {
                path: 'product-orders',
                component: ProductOrdersComponent
            },
            {
                path: 'post-orders',
                component: PostOrdersComponent
            },
            {
                path: 'payments',
                component: PaymentComponent
            },
            {
                path: 'enquiry',
                component: EnquiryComponent
            },
            {
                path: 'user-register',
                component: UserRegistrationComponent
            },
            {
                path: 'business-info',
                component: BusinessInfoComponent
            },
            {
                path : 'business-details',
                component: BusinessDetailsComponent
            },
            {
                path : 'whatsapp',
                component: WhatsappComponent
            }
        ]
    },
    {
        path: '',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'recover-password',
        component: RecoverPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true},)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
