import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ConfigService} from "@/config.service";
import {HttpClient} from "@angular/common/http";
import {LoginDetails, LoginUserDetails} from "@/model/login/LoginDetails";
import {FormGroup, UntypedFormGroup} from "@angular/forms";
import {LocalDataService} from "@services/localDataService";
import {UserDetails} from "@/model/user-details/UserDetails";
import {ProductDetails} from "@/model/product/ProductDetails";
import {PostDetails} from "@/model/post/PostDetails";
import {ProductOrders} from "@/model/orders/ProductOrders";
import {PostOrders} from "@/model/orders/PostOrders";
import {PaymentDetails} from "@/model/payment/PaymentDetails";
import {UserDetailsData} from "@/model/user-details/UserDetailsData";
import {Enquiry} from "@/model/enquiry/Enquiry";
import { BusinessDetails } from '@/model/business/BusinessDetails';
import * as countrycitystatejson from 'countrycitystatejson';
import {CommodityDetails} from "@/model/commodity/CommodityDetails";
import { FarmerDetails } from '@/model/farmers/farmer-details';
import { BusinessDocumentDetails } from '@/model/business/BusinessDocumentDetails';
import { PieChart } from '@/model/pieChart/pieChart';
import {WhatsAppMessage} from "@/model/whatsapp/WhatsAppMessage";
import {OutboxList} from "@/model/whatsapp/OutboxList";

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;
    private countryData = countrycitystatejson;
    constructor(private router: Router, private toastr: ToastrService,
                private configService: ConfigService, private http: HttpClient, private localData: LocalDataService) {
    }

    async loginByAuth({email, password}) {
        try {
            const token = String(this.localData.getHeaders());
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('LoginDetails success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByAuth({email, password}) {
        try {
            const token = String(this.localData.getHeaders());
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('Register success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async loginByGoogle() {
        try {
            const token = String(this.localData.getHeaders());
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('LoginDetails success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByGoogle() {
        try {
            const token = String(this.localData.getHeaders());
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('Register success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async loginByFacebook() {
        try {
            const token = String(this.localData.getHeaders());
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('LoginDetails success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByFacebook() {
        try {
            const token = String(this.localData.getHeaders());
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('Register success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async getProfile() {
        try {
            this.user = localStorage.getItem('authToken');
        } catch (error) {
            this.logout();
            throw error;
        }
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('gatekeeper_token');
        this.user = null;
        this.router.navigate(['/login']);
    }


    //Send OTP
    sendOtpVerify(userForm: UntypedFormGroup) {
        return this.http.post<LoginUserDetails>(
            this.configService.getConfig().END_POINT + "/auth/user/send-otp", userForm,
        )
    }


    //Verify OTP
    verifyOtp(verifyOtp: any) {
        return this.http.post<LoginDetails>(
            this.configService.getConfig().END_POINT + "/auth/user/verify", verifyOtp
        )
    }


    //Login by MPIN
    loginByMPin(verifyMPin) {
        return this.http.post<LoginDetails>(
            this.configService.getConfig().END_POINT + "/auth/user/mobile/login", verifyMPin,
        )
    }


    //Get all registered details
    getAllUserRegisteredDetails() {
        return this.http.get<UserDetails[]>(
            this.configService.getConfig().END_POINT + "/api/admin/registration/getAll",
            {headers: this.localData.getHeaders()}
        )
    }

    //Get Registered Detail based on user registration ID
    getRegisteredDetailsById(userRegisterID: number) {
        return this.http.get<UserDetails>(
            this.configService.getConfig().END_POINT + "/api/admin/registration/get/" + userRegisterID,
            {headers: this.localData.getHeaders()}
        )
    }

    updateVerificationStatus(updateVerify: any) {
        return this.http.put<UserDetails>(
            this.configService.getConfig().END_POINT + "/api/admin/update/verification_status", updateVerify,
            {headers: this.localData.getHeaders()}
        )
    }

    getAssetsImages(assetId: number) {
        return this.http.get(
            this.configService.getConfig().END_POINT + "/api/assetDetails/getAsset/" + assetId
        )
    }

    getAllProductDetails() {
        return this.http.get<ProductDetails[]>(
            this.configService.getConfig().END_POINT + "/api/product/getAll",
            {headers: this.localData.getHeaders()}
        )
    }

    //get product details by product ID
    getProductDetailsById(productId: number) {
        return this.http.get<ProductDetails>(
            this.configService.getConfig().END_POINT + "/api/product/getProduct/" + productId,
            {headers: this.localData.getHeaders()}
        )
    }

    //update post verification status
    updateProductStatus(productStatus: any) {
        return this.http.put<ProductDetails>(
            this.configService.getConfig().END_POINT + "/api/product/updateApprovalStatus", productStatus,
            {headers: this.localData.getHeaders()}
        )
    }


    //Get all post details
    getAllPostDetails() {
        return this.http.get<PostDetails[]>(
            this.configService.getConfig().END_POINT + "/api/post/getAll",
            {headers: this.localData.getHeaders()}
        )
    }

    //Get PostDetailsId based Post Detail
    getPostDetailsById(postID: number) {
        return this.http.get<PostDetails>(
            this.configService.getConfig().END_POINT + "/api/post/get/" + postID,
            {headers: this.localData.getHeaders()}
        )
    }

    //update post verification status
    updatePostStatus(postStatus: any) {
        return this.http.put<PostDetails>(
            this.configService.getConfig().END_POINT + "/api/post/updateApprovalStatus", postStatus,
            {headers: this.localData.getHeaders()}
        )
    }


    //get all product orders
    getAllProductOrders() {
        return this.http.get<ProductOrders[]>(
            this.configService.getConfig().END_POINT + "/api/orders/product/get-all",
            {headers: this.localData.getHeaders()}
        )
    }

    //get all post orders
    getAllPostOrders() {
        return this.http.get<PostOrders[]>(
            this.configService.getConfig().END_POINT + "/api/orders/post/get-all",
            {headers: this.localData.getHeaders()}
        )
    }

    //get all payments
    getAllPayments() {
        return this.http.get<PaymentDetails[]>(
            this.configService.getConfig().END_POINT + "/api/payment-details/getAll",
            {headers: this.localData.getHeaders()}
        )
    }

    //get product order by ID
    getProductOrderByID(productOrderId: number) {
        return this.http.get<ProductOrders>(
            this.configService.getConfig().END_POINT + "/api/orders/product/get/" + productOrderId,
            {headers: this.localData.getHeaders()}
        )
    }

    //get post order by ID
    getPostOrderByID(postOrderId: number) {
        return this.http.get<PostOrders>(
            this.configService.getConfig().END_POINT + "/api/orders/post/get/" + postOrderId,
            {headers: this.localData.getHeaders()}
        )
    }

    //get user ID based products & posts show
    getUserIdBasedDetails(userId: number) {
        return this.http.get<UserDetailsData>(
            this.configService.getConfig().END_POINT + "/api/admin/getUsersDataById/" + userId,
            {headers: this.localData.getHeaders()}
        )
    }

    //Get all Enquiry Details
    getAllEnquiryDetails() {
        return this.http.get<Enquiry[]>(
            this.configService.getConfig().END_POINT + "/api/enquires/all-enquires",
            {headers: this.localData.getHeaders()}
        )
    }

    //Save user details
    saveUserRegisterDetails(formGroup: FormGroup) {
        return this.http.post<UserDetails>(
            this.configService.getConfig().END_POINT + "/auth/registration/add-user", formGroup,
        )
    }

    //Save user details by admin
    saveUserRegisterDetailsByAdmin(formGroup: FormGroup) {
        return this.http.post<UserDetails>(
            this.configService.getConfig().END_POINT + "/auth/registration/addUser/byAdmin", formGroup,
        )
    }

    //Save service provider Business Details
    saveServiceProviderBusinessDetails(userRegId: number, businessDetails: FormGroup) {
        return this.http.post(
            this.configService.getConfig().END_POINT + "/api/admin/business-details/add/" + userRegId, businessDetails,
            {headers: this.localData.getHeaders()}
        )
    }

    //save Farmers & FPO details
    saveFarmerDetails(userRegId: number, formGroup: FormGroup) {
        return this.http.post(
            this.configService.getConfig().END_POINT + "/api/admin/farm-details/add/" + userRegId, formGroup,
            {headers: this.localData.getHeaders()}
        )
    }

    //get city form India, Tamil nadu
    getCitiesByState(country: string, state: string) {
        return this.countryData.getCities(country, state);
    }

    //get all commodity list
    getCommodityList() {
        return this.http.get<CommodityDetails[]>(
            this.configService.getConfig().END_POINT + "/api/commodity/getAll",
        )
    }

    //get all FPO Details
    getAllFPODetails() {
        return this.http.get<UserDetails[]>(
            this.configService.getConfig().END_POINT + "/api/admin/fpo/get-all",
            {headers: this.localData.getHeaders()}
        )
    }

    //Id based on Farms details get
    getFarmerDetailsById(farmerId: number) {
        return this.http.get<FarmerDetails[]>(
            this.configService.getConfig().END_POINT + "/api/farm-details/get/" + farmerId,
            {headers: this.localData.getHeaders()}
        )
    }

    //Update Farms details for admin
    updateFarmerDetailsByAdmin(userId  : number,farmerDetails: FarmerDetails) {
        return this.http.put<FarmerDetails>(
            this.configService.getConfig().END_POINT + "/api/admin/farm-details/update/" + userId, farmerDetails,
            {headers: this.localData.getHeaders()}
        )
    }

    //Id based on Business details get
    getBusinessDetailsById(businessId: number) {
        return this.http.get<BusinessDetails>(
            this.configService.getConfig().END_POINT + "/api/business-details/get/" + businessId,
            {headers: this.localData.getHeaders()}
        )
    }

    //Update Business details for admin
    updateBusinessDetailsByAdmin(userId  : number, businessDetails: BusinessDetails) {
        return this.http.put<BusinessDetails>(
            this.configService.getConfig().END_POINT + "/api/admin/business-details/update/" + userId, businessDetails,
            {headers: this.localData.getHeaders()}
        )
    }

    // Id based delete business details images
    deleteBusinessDetailsImages(assetId: number) {
        return this.http.delete(
            this.configService.getConfig().END_POINT + "/api/document-details/files/delete/" + assetId ,
            {headers: this.localData.getHeaders()}
        )
    }

    // Update business details images
    updateAdminBusinessDetailsImages(userId  : number,userRegId : number,businessDocumentDetails: BusinessDocumentDetails[]) {
        return this.http.post<BusinessDocumentDetails[]>(
            this.configService.getConfig().END_POINT + "/api/admin/business-details/more-document/" + userId + '/' + userRegId, businessDocumentDetails,
            {headers: this.localData.getHeaders()}
        )
    }

    // getPieChartValues
    getPieChartValues(){
        return this.http.get<PieChart[]>(
            this.configService.getConfig().END_POINT + "/api/admin/chart/summary",
            {headers: this.localData.getHeaders()}
        )
    }

    //Update profile details by ADMIN
    updateProfile(updateForm: FormGroup) {
        return this.http.put<UserDetails>(
            this.configService.getConfig().END_POINT + "/api/registration/update", updateForm,
            {headers: this.localData.getHeaders()}
        )
    }

    //update product details by ADMIN based on regId & businessId
    updateProductDetails(regId: number, businessId: number, updateProductForm: FormGroup)  {
        return this.http.put<ProductDetails>(
            this.configService.getConfig().END_POINT + "/api/admin/product/update/" +regId + '/' + businessId , updateProductForm,
            {headers: this.localData.getHeaders()}
        )
    }


    //set whatsapp message
    sendWhatsAppMessage(messageBody: WhatsAppMessage) {
        return this.http.post<OutboxList[]>(
            this.configService.getConfig().END_POINT + '/api/schedular/set', messageBody,
            {headers: this.localData.getHeaders()}
        )
    }

}
