import {Component, OnInit, Renderer2} from "@angular/core";
import {UserDetails} from "@/model/user-details/UserDetails";
import {environment} from "../../../environments/environment";
import {catchError, EMPTY, Subject, takeUntil, tap} from 'rxjs';
import {ToastrService} from "ngx-toastr";
import {AppService} from "@services/app.service";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {ConfigService} from "@/config.service";
import {FormBuilder} from "@angular/forms";
import {DatePipe} from "@angular/common";
import {LoggingService} from "@services/logging.service";
import {WhatsAppMessage} from "@/model/whatsapp/WhatsAppMessage";
import {OutboxList} from "@/model/whatsapp/OutboxList";
import {MessageDetails} from "@/model/whatsapp/MessageDetails";

@Component({
    selector: 'app-whatsapp',
    templateUrl: './whatsapp.component.html',
    styleUrls: ['./whatsapp.component.scss']
})

export class WhatsappComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    searchText;
    userDetailsList: UserDetails [] = [];
    tableUserDetailsList: UserDetails [] = [];
    regDetailsList: UserDetails [] = [];
    fpoFilter: string | null = null;
    fpoList: String[] = [];
    userTypeFilter: string | null = null;
    userType: String[] = [];
    userDateFilter: string | null = null;
    date: String[] = [];
    userLocationFilter: string | null = null;
    userLocation: String[] = [];
    isLocalhost = window.location.hostname === 'localhost';
    messageModal: boolean = false;
    messageContent: string = "";
    userMessageContent: string;
    messageDate: string = "";
    initializationTime: number;
    whatsAppMessage: WhatsAppMessage | undefined;
    messageError: string;
    dateError: string;
    userMessageError: string;
    todayDate: string;
    todayEpochDate: number;
    userDetailsById: UserDetails | undefined;
    scheduledTime: string;
    outBoxList: OutboxList[] = [];

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private configService: ConfigService,
        private formBuilder: FormBuilder,
        private datePipe: DatePipe,
        private loggingService: LoggingService,
    ) {
        const today = new Date();
        this.todayDate = today.toISOString().split('T')[0];
        this.todayEpochDate = today.getTime();
        console.log(this.todayEpochDate)
    }

    ngOnInit() {
        this.getAllRegisteredDetailsList();
    }


    /* Get all Registration Details List*/
    getAllRegisteredDetailsList() {
        this.spinner.show();

        this.appService.getAllUserRegisteredDetails()
            .pipe(
                takeUntil(this.destroy$),
                tap((data) => {
                    //Create a array for Enrollment Type and remove Duplicate value from enrollmentType.
                    this.userType = Array.from(new Set(data.map(type => type.enrollmentType))).sort((a, b) => a.localeCompare(b));
                    //Create a array for Farm Details form FPO Name and remove Duplicate value from enrollmentType.
                    this.fpoList = Array.from(
                        new Set(
                            data
                                .filter(item => item?.farmDetails?.length > 0 && item.farmDetails[0]?.fpoName) // Ensure farmDetails exists and has a valid fpoName
                                .map(item => item.farmDetails[0].fpoName) // Extract the fpoName
                        )
                    ).sort((a, b) => a.localeCompare(b));

                    console.log(this.fpoList);
                    //Create a array for createAt(Date EPOCH value) and remove Duplicate value from createdAt.
                    const uniqueDates = Array.from(new Set(data.map(item => item.createdAt)));
                    //Convert into Epoch value to Normal Date
                    const localDate = uniqueDates.map(epoch => this.datePipe.transform(epoch, 'dd/MM/yyyy'));
                    // After Convert Normal Date remove Duplicate.
                    this.date = Array.from(new Set(localDate));
                    this.date.sort((a, b) => {
                        // Parse the dates to a comparable format
                        const dateA = new Date(a.split('/').reverse().join('/')).getTime();
                        const dateB = new Date(b.split('/').reverse().join('/')).getTime();
                        return dateB - dateA; // Descending order
                    });
                    //Location Data filter if address list greater than 0
                    const location = data.filter(location => location.userAddress?.length > 0);
                    //User Location List remove Duplicate From address and First Address filter
                    this.userLocation = Array.from(new Set(location.map(city => city.userAddress[0].city.toLowerCase())));
                    if (!environment.production && this.isLocalhost) {
                        console.log('Date Filter::', this.date);
                        console.log('Location File', this.userLocation)
                    }
                }),
                catchError(error => {
                    console.error('Error fetching user details:', error);
                    this.spinner.hide();
                    this.toastr.error(
                        error.error || error.error.message
                            ? error.error.message
                            : 'Something went wrong, please try again'
                    );
                    return EMPTY;
                })
            )
            .subscribe(data => {
                this.spinner.hide();
                this.userDetailsList = data;
                this.tableUserDetailsList = this.userDetailsList.sort((a, b) => b.userRegistrationId - a.userRegistrationId).filter(userType => userType.enrollmentType == 'Farmers');
                this.regDetailsList = this.tableUserDetailsList;
                if (!environment.production && this.isLocalhost) {
                    console.log('Registered List:', this.userDetailsList);
                }
            });
    }

    userFilter(event: any, filterType: string) {
        const value = event.target.value;
        if (value != 'All') {
            switch (filterType) {
                case 'fpoType' :
                    this.fpoFilter = value !== 'Select FPO' ? value : null;
                    break;
                case 'date' :
                    this.userDateFilter = value !== 'Select Date' ? value : null;
                    break;
                case 'location' :
                    this.userLocationFilter = value !== 'Select User Location' ? value : null;
                    break;
                default:
                    break;
            }
            this.applyUserFilter();
        } else {
            this.tableUserDetailsList = this.userDetailsList;
        }
    }


    // User Type, Date, City based Filter Functionality
    applyUserFilter() {
        /*const date = this.userDateFilter ? userFilter.filter(date => {
            const options: Intl.DateTimeFormatOptions = {day: '2-digit', month: '2-digit', year: 'numeric'};
            const formattedDate = new Date(date.createdAt).toLocaleDateString('en-GB', options);
            return formattedDate === this.userDateFilter;
        }) : userFilter;
        const locationCity = date.filter(location => location.userAddress?.length > 0)
        this.tableUserDetailsList = this.userLocationFilter ? locationCity.filter(location => location.userAddress[0].city.toLowerCase() === this.userLocationFilter.toLowerCase()) : date;*/
        this.tableUserDetailsList = this.fpoFilter ? this.userDetailsList.filter(item => item?.farmDetails?.length > 0 && item.farmDetails[0]?.fpoName == this.fpoFilter) : this.regDetailsList;
        if (!environment.production && this.isLocalhost) {
            console.log('Filter Data::', this.tableUserDetailsList);
        }
    }

    sendGroupWhatsappMessage() {
        if (!this.messageContent || !this.messageDate) {
            // Ensure both fields are checked for error states
            const contentField: any = document.querySelector('[name="messageContent"]');
            const dateField: any = document.querySelector('[name="messageDate"]');

            contentField?.classList.add('is-invalid');
            dateField?.classList.add('is-invalid');

            this.messageError = 'Please provide a valid message content and date.';
            return;
        } else {
            const date = new Date(this.messageDate);
            this.initializationTime = date.getTime();
            console.log(this.initializationTime);
            this.sendWhatsAppMessageContent();
        }
    }


    sendWhatsAppMessageContent() {
        const messageDetails = {
            messageId: 0,
            messageName: '',
            messageContent: this.messageContent,
            language: 'Tamil',
            createdBy:  0,
            createdAt: 0,
            updatedBy: 0,
            updatedAt: 0,
            state: 'ACTIVE'
        };
        const outBoxList: OutboxList[] = this.tableUserDetailsList.map(req => ({
            messageContent: this.messageContent,
            mobileNumber: req?.countryCode + req.whatsappNumber,
            userId: req.userRegistrationId,
            outboxId: 0,
            scheduledAt: 0,
            scheduledBy: 0,
            retryCount: 0,
            messageStatus: '',
            messageDetails: messageDetails,
            sessionId: '',
        }));

        console.log(outBoxList);

        const whatsAppBody = {
            initializationTime: this.initializationTime,
            outboxList: outBoxList
        }

        console.log('body', whatsAppBody);
        this.sendMessage(whatsAppBody, 'group');
    }


    sendWhatsappMessage(userDetails: any) {
        this.messageModal = true;
        console.log('user details by id', userDetails);
        this.userDetailsById = userDetails;
    }


    cancelMessage() {
        this.messageModal = false;
    }


    sendMessageByUser() {
        if (!this.userMessageContent) {
            const userMessageContentField: any = document.querySelector('[name="userMessageContent"]');
            userMessageContentField?.classList.add('is-invalid');
            return;
        } else {
            this.sendWhatsAppMessageByUser();
        }
    }


    sendWhatsAppMessageByUser() {
        const today = new Date();
        this.todayEpochDate = today.getTime();

        const messageDetails = {
            messageId: 0,
            messageName: '',
            messageContent: this.userMessageContent,
            language: 'Tamil',
            createdBy:  0,
            createdAt: 0,
            updatedBy: 0,
            updatedAt: 0,
            state: 'ACTIVE'
        };
        const outBoxList = [
            {
                messageContent: this.userMessageContent,
                mobileNumber: this.userDetailsById?.countryCode + this.userDetailsById?.whatsappNumber,
                userId: this.userDetailsById.userRegistrationId,
                outboxId: 0,
                scheduledAt: 0,
                scheduledBy: 0,
                retryCount: 0,
                messageStatus: '',
                messageDetails: messageDetails,
                sessionId: '',
            }
        ];

        const userWhatsAppBody = {
            initializationTime: this.todayEpochDate,
            outboxList: outBoxList
        }
        console.log(userWhatsAppBody);
        this.sendMessage(userWhatsAppBody, 'single');
    }


    sendMessage(requestBody: any, type: string) {
        this.spinner.show();
        this.appService.sendWhatsAppMessage(requestBody)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (data) => {
                    this.outBoxList = data;
                    console.log('whatsapp response::', this.outBoxList);

                    if (type === 'single') {
                        const scheduledAt = this.outBoxList?.[0].scheduledAt;

                        if (scheduledAt) {
                            const date = new Date(scheduledAt); // Convert epoch to Date object
                            this.scheduledTime = `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                hour12: true
                            })}`;
                            console.log('Formatted Date with AM/PM:', this.scheduledTime);
                            this.toastr.success(`Your message is scheduled at ${this.scheduledTime}`, 'Success', {
                                toastClass: 'ngx-toastr custom-toast-success',
                                timeOut: 5000,
                                progressBar: true,
                                progressAnimation: 'increasing',
                            });

                        } else {
                            this.toastr.warning('Scheduled time not found in the response.');
                        }
                    } else {
                        this.toastr.success('Your message has been scheduled successfully');
                    }

                    this.spinner.hide();
                    this.messageModal = false;
                },
                (error: any) => {
                    console.error('Error:', error);
                    this.spinner.hide();
                    const errorMessage = error?.error?.message || 'Something went wrong, please try again';
                    this.toastr.error(errorMessage);
                }
            );
    }

}