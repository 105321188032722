<section class="content">
    <div class="container-fluid">
        <div class="row">
            <form [formGroup]="userRegisterForm">
                <div class="row mt-3">
                    <div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                                (change)="userType($event)" formControlName="enrollmentType">
                                <option selected hidden value="">Select User Type</option>
                                <option value="Farmers">Farmers</option>
                                <option value="FPO">FPO</option>
                                <option value="Service Providers">Service Providers</option>
                                <option selected value="Commodity Processors">Commodity Processors</option>
                            </select>
                            <label for="floatingSelect" class="text-gray font-weight-lighter">User Type</label>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Name" formControlName="name"
                                   [ngClass]="{'is-invalid': userRegisterForm.get('name')?.invalid && userRegisterForm.get('name')?.touched,
                                                            'is-valid': userRegisterForm.get('name')?.valid && userRegisterForm.get('name')?.touched}">
                            <label class="text-gray font-weight-lighter">Name<span class="text-danger">*</span></label>
                        </div>
                        <span *ngIf="userRegisterError.name"
                              class="text-danger span ml-2">{{userRegisterError.name}}</span>
                    </div>

                    <div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="tel" class="form-control" placeholder="Phone Number"
                                formControlName="phoneNumber" (keypress)="OnlyNumbers($event)" minlength="10"
                                maxlength="10"
                                [ngClass]="{'is-invalid': userRegisterForm.get('phoneNumber')?.invalid && userRegisterForm.get('phoneNumber')?.touched,
                                                            'is-valid': userRegisterForm.get('phoneNumber')?.valid && userRegisterForm.get('phoneNumber')?.touched}">
                            <label class="text-gray font-weight-lighter">Phone Number<span
                                    class="text-danger">*</span></label>
                        </div>
                        <span *ngIf="userRegisterError.phoneNumber"
                            class="text-danger span ml-2">{{userRegisterError.phoneNumber}}</span>
                    </div>

                    <div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="email" class="form-control" placeholder="Email Address"
                                formControlName="email">
                            <label class="text-gray font-weight-lighter">Email Address</label>
                        </div>
                    </div>

                    <div *ngIf="isFarmers" class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <select class="form-select" aria-label="Floating label select example"
                                formControlName="farmerRole">
                                <option selected hidden value="">Select Role</option>
                                <option value="Member">Member</option>
                                <option value="President">President</option>
                                <option value="Secretary">Secretary</option>
                                <option value="Treasurer">Treasurer</option>
                                <option value="Executive member">Executive member</option>
                            </select>
                            <label class="text-gray font-weight-lighter">Farmer Role</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <select class="form-select" aria-label="Floating label select example"
                                (change)="selectCity($event, 'City')" formControlName="city"
                                [ngClass]="{'is-invalid': userRegisterForm.get('city')?.invalid && userRegisterForm.get('city')?.touched,
                                                            'is-valid': userRegisterForm.get('city')?.valid && userRegisterForm.get('city')?.touched}">
                                <option selected hidden value="">Select City</option>
                                <option *ngFor="let city of districtsList" [value]="city" >{{city}}</option>
                            </select>
                            <label for="floatingSelect" class="text-gray font-weight-lighter">City<span
                                    class="text-danger">*</span></label>
                        </div>
                        <span *ngIf="userRegisterError.city" class="text-danger span ml-2">{{ userRegisterError.city
                            }}</span>
                    </div>

                    <div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Pin Code" maxlength="6" minlength="6"
                                formControlName="pinCode">
                            <label class="text-gray font-weight-lighter">Pin Code</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input class="form-control" type="file" id="formFileMultiple" multiple
                                (change)="handleFileInput($event)">
                            <label class="text-gray font-weight-lighter">Proof</label>
                        </div>
                    </div>
                </div>
            </form>

            <form [formGroup]="businessRegForm">
                <div class="row mt-3 mb-3" *ngIf="isFarmers">
                    <div class="col-12 mb-2">
                        <h6 class="text-bold">Farm Details</h6>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <select class="form-select" aria-label="Floating label select example"
                                formControlName="primaryCropsGrown" (click)="FarmMapping($event)">
                                <option selected hidden value="">Select Primary Crops</option>
                                <option *ngFor="let commodity of commodityDetails" [value]="commodity.commodityName">
                                    {{commodity.commodityName}}</option>
                            </select>
                            <label for="floatingSelect" class="text-gray font-weight-lighter">Primary Crops</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <select class="form-select" aria-label="Floating label select example"
                                formControlName="fpoName" (change)="selectFPO($event)">
                                <option selected hidden value="">Select FPO Name</option>
                                <option *ngFor="let FPO of fpoDetailList" [value]="FPO.name" [selected]="FPO.name">
                                    {{FPO.name}}</option>
                            </select>
                            <label class="text-gray font-weight-lighter">FPO Name</label>
                        </div>
                    </div>

                    <!--<div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <select class="form-select" aria-label="Floating label select example"
                                (change)="selectCity($event, 'Farm Location')" formControlName="farmLocation">
                                <option selected hidden value="">Select Farm Location</option>
                                <option *ngFor="let city of cities" [value]="city">{{city}}</option>
                            </select>
                            <label for="floatingSelect" class="text-gray font-weight-lighter">Farm Location</label>
                        </div>
                    </div>-->
                    <div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Farm Location"
                                formControlName="farmLocation" (input)="selectCity($event, 'Farm Location')">
                            <label class="text-gray font-weight-lighter">Farm Location</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="FPO Link Name"
                                formControlName="fpoLinkName" readonly>
                            <label class="text-gray font-weight-lighter">FPO Link Name</label>
                        </div>
                    </div>
                </div>

                <div class="row mt-3 mb-3" *ngIf="isFPO">
                    <div class="col-12 mb-2">
                        <h6 class="text-bold">FPO Details</h6>
                    </div>

                    <div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="FPO Name" formControlName="fpoName">
                            <label class="text-gray font-weight-lighter">FPO Name</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Name"
                                formControlName="fpoRegistrationNumber">
                            <label class="text-gray font-weight-lighter">FPO Register Number</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="FPO Location" formControlName="fpoCity"
                                (input)="selectCity($event, 'FPO Location')">
                            <label class="text-gray font-weight-lighter">FPO Location</label>
                        </div>
                        <!--<div class="form-floating">
                            <select class="form-select" aria-label="Floating label select example"
                                formControlName="fpoCity" (input)="selectCity($event, 'FPO Location')">
                                <option selected hidden value="">Select FPO Location</option>
                                <option *ngFor="let city of cities" [value]="city">{{city}}</option>
                            </select>
                            <label for="floatingSelect" class="text-gray font-weight-lighter">FPO Location</label>
                        </div>-->
                    </div>
                </div>

                <div class="row mt-3 mb-3" *ngIf="isCommodityProcessors">
                    <div class="col-12 mb-2">
                        <h6 class="text-bold">Commodity Processors Details</h6>
                    </div>

                    <div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Business Name" formControlName="businessName">
                            <label class="text-gray font-weight-lighter">Business Name</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Location"
                                   formControlName="businessLocation">
                            <label class="text-gray font-weight-lighter">Location</label>
                        </div>
                    </div>

                   <!-- <div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="License Number"
                                   formControlName="businessLicenseNumber">
                            <label class="text-gray font-weight-lighter">License Number</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="GST No"
                                   formControlName="gstNo">
                            <label class="text-gray font-weight-lighter">GST No</label>
                        </div>
                    </div>-->
                </div>
            </form>

            <form [formGroup]="serviceRegFrom">
                <div class="row mt-3 mb-3" *ngIf="isServiceProvider">

                    <div class="col-12 mb-2">
                        <h6 class="text-bold">Service Provider Details</h6>
                    </div>

                    <div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Business Name"
                                formControlName="businessName">
                            <label class="text-gray font-weight-lighter">Business Name</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <select class="form-select" aria-label="Floating label select example"
                                formControlName="typeOfService" placeholder="Select Type of Service">
                                <option selected hidden value="">Select Type of Service</option>
                                <option value="Pest Control">Pest Control</option>
                                <option value="Soil Testing">Soil Testing</option>
                                <option value="Equipment Rental">Equipment Rental</option>
                                <option value="Transportation">Transportation</option>
                                <option value="Irrigation Services">Irrigation Services</option>
                                <option value="Consultancy">Consultancy</option>
                                <option value="Others">Others</option>
                            </select>
                            <label for="floatingSelect" class="text-gray font-weight-lighter">Type of Service</label>
                        </div>
                    </div>
                </div>
            </form>


            <form [formGroup]="serviceRegFrom">
                <div class="row mt-3 mb-3" *ngIf="isServiceProvider">

                    <div class="col-12 mb-2">
                        <h6 class="text-bold">Service Provider Details</h6>
                    </div>

                    <div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <input type="text" class="form-control" placeholder="Business Name"
                                   formControlName="businessName">
                            <label class="text-gray font-weight-lighter">Business Name</label>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-12 mb-2">
                        <div class="form-floating">
                            <select class="form-select" aria-label="Floating label select example"
                                    formControlName="typeOfService" placeholder="Select Type of Service">
                                <option selected hidden value="">Select Type of Service</option>
                                <option value="Pest Control">Pest Control</option>
                                <option value="Soil Testing">Soil Testing</option>
                                <option value="Equipment Rental">Equipment Rental</option>
                                <option value="Transportation">Transportation</option>
                                <option value="Irrigation Services">Irrigation Services</option>
                                <option value="Consultancy">Consultancy</option>
                                <option value="Others">Others</option>
                            </select>
                            <label for="floatingSelect" class="text-gray font-weight-lighter">Type of Service</label>
                        </div>
                    </div>
                </div>
            </form>

            <div class="d-flex justify-content-center align-items-center mb-5">
                <button class="btn btn-success rounded-pill" (click)="userRegisterValidation()">Register</button>
            </div>
            <div class="row mb-5"></div>
        </div>
    </div>
</section>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" type="jelly-box">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>

<div class="modal" tabindex="-1" [style.display]="otpModal ? 'block' : 'none'"
    style="background-color: rgba(87,86,86,0.64)">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <div class="text-center">
                    <h3>Token No: {{randomNumber}}</h3>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close"
                    (click)="popupClose()">Close
                </button>
            </div>
        </div>
    </div>
</div>