import { Component, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AppService } from "@services/app.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subject, takeUntil } from 'rxjs';
import { UserDetails } from "@/model/user-details/UserDetails";
import { CommodityDetails } from "@/model/commodity/CommodityDetails";

@Component({
    selector: 'app-user-register',
    templateUrl: './user-registration.component.html',
    styleUrls: ['./user-registration.component.scss']
})

export class UserRegistrationComponent implements OnInit {

    destroy$: Subject<boolean> = new Subject<boolean>();
    //base64 file list
    base64FileList: string[] = [];

    cities: string[];

    farmLocation: string = "";
    fpoLinkName: string = "";
    fpoName: string = "";
    isFarmers: boolean = false;
    isFPO: boolean = false;
    isServiceProvider: boolean = false;
    isCommodityProcessors: boolean = false;
    otpModal: boolean = false;
    randomNumber: number;

    userDetails: UserDetails | undefined;

    userRegistrationId: number = 0;

    commodityDetails: CommodityDetails[] = [];
    fpoDetailList: UserDetails[] = [];

    districtsList = [
        "Ariyalur",
        "Chengalpattu",
        "Chennai",
        "Coimbatore",
        "Cuddalore",
        "Dharmapuri",
        "Dindigul",
        "Erode",
        "Kallakurichi",
        "Kanchipuram",
        "Kanniyakumari",
        "Karur",
        "Krishnagiri",
        "Madurai",
        "Mayiladuthurai",
        "Nagapattinam",
        "Namakkal",
        "Nilgiris",
        "Perambalur",
        "Pudukkottai",
        "Ramanathapuram",
        "Ranipet",
        "Salem",
        "Sivagangai",
        "Tenkasi",
        "Thanjavur",
        "Theni",
        "Thoothukudi",
        "Tiruchirappalli",
        "Tirunelveli",
        "Tirupathur",
        "Tiruppur",
        "Tiruvallur",
        "Tiruvannamalai",
        "Tiruvarur",
        "Vellore",
        "Viluppuram",
        "Virudhunagar"
    ]

    constructor(private route: Router, private appService: AppService,
        private spinner: NgxSpinnerService, private toastr: ToastrService) {
        this.cities = this.appService.getCitiesByState('IN', 'Tamil Nadu');
        console.log(this.cities);
    }

    userRegisterForm = new UntypedFormGroup({
        userRegistrationId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        name: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        countryCode: new UntypedFormControl("91", [Validators.required, Validators.nullValidator]),
        phoneNumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
        email: new UntypedFormControl("", [Validators.required, Validators.nullValidator, Validators.email]),
        enrollmentType: new UntypedFormControl("Commodity Processors", [Validators.required, Validators.nullValidator]),
        accountStatus: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        selfDescription: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        city: new UntypedFormControl("Madurai", [Validators.required, Validators.nullValidator]),
        pinCode: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        createdBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        createdAt: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
        updatedBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        updatedAt: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
        farmerRole: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        verificationImageList: new UntypedFormArray([]),
        addressDetails: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    });

    businessRegForm = new UntypedFormGroup({
        farmDetailsId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        primaryCropsGrown: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        fpoLinkName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        fpoName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        fpoRegistrationNumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        fpoCity: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        farmLocation: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        farmAddress: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        commodityId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        fpoRegId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        gstNo:new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        businessLicenseNumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        businessName:new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        businessAddress:new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        businessLocation: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    })

    serviceRegFrom = new UntypedFormGroup({
        businessName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        typeOfService: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    })

    public userRegisterError = {
        name: '',
        phoneNumber: '',
        city: '',
    }

    userRegisterValidation() {
        this.userRegisterError.city = ''
        this.userRegisterError.phoneNumber = ''

        let hasError = false;
        if (this.userRegisterForm.get('name')?.invalid) {
            this.userRegisterError.name = "Name is required";
            hasError = true;
        }

        if (this.userRegisterForm.get('phoneNumber')?.invalid) {
            if (this.userRegisterForm.get('phoneNumber').errors.required) {
                this.userRegisterError.phoneNumber = "Phone Number is required";
            }
            if (this.userRegisterForm.get('phoneNumber').errors.pattern) {
                this.userRegisterError.phoneNumber = "Please, Enter 10 digit Valid Phone Number.";
            }
            hasError = true;
        }
        if (this.userRegisterForm.get('city')?.invalid) {
            this.userRegisterError.city = "City is required";
            hasError = true;
        }

        if (!hasError) {
            this.saveUserDetails();
        }
    }


    ngOnInit() {
        this.getAllCommodity();
        this.userType({ target: { value: 'Commodity Processors' } });
    }


    OnlyNumbers($event) {
        let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
        let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft'];
        if (specialKeys.indexOf($event.key) !== -1) {
            return;
        } else {
            if (regex.test($event.key)) {
                return true;
            } else {
                return false;
            }
        }
    }


    getAllCommodity() {
        this.spinner.show();
        this.appService.getCommodityList()
            .pipe(takeUntil(this.destroy$))
            .subscribe((commodity) => {
                this.spinner.hide();
                this.commodityDetails = commodity;
                console.log('commodity list::', this.commodityDetails);
            }, (error: any) => {
                console.error("Error:", error);
                this.spinner.hide();
                const errorMessage = error?.error?.message || "Something went wrong, please try again";
                this.toastr.error(errorMessage);
            }
            );
    }

    getAllFPODetails() {
        this.spinner.show();
        this.appService.getAllFPODetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe((fpoDetails) => {
                this.spinner.hide();
                this.fpoDetailList = fpoDetails;
                this.fpoName = fpoDetails[0].name;
                this.businessRegForm.controls['fpoRegId'].setValue(fpoDetails[0].userRegistrationId);
                console.log('FPO list::', this.fpoDetailList);
            }, (error: any) => {
                console.error("Error:", error);
                this.spinner.hide();
                const errorMessage = error?.error?.message || "Something went wrong, please try again";
                this.toastr.error(errorMessage);
            }
            );
    }


    onSubmit() {
        this.saveUserDetails();
    }

    userType(event: any) {
        const userType = event.target.value;
        if (userType == 'Farmers') {
            this.isFarmers = true;
            this.isFPO = false;
            this.isServiceProvider = false;
            this.isCommodityProcessors = false;
            this.getAllFPODetails();
            this.userRegisterForm.controls['farmerRole'].setValue('Member');
            this.businessRegForm.controls['fpoName'].setValue(this.fpoName);
            console.log(this.userRegisterForm.value);
        } else if (userType == 'FPO') {
            this.isFarmers = false;
            this.isFPO = true;
            this.isServiceProvider = false;
            this.isCommodityProcessors = false;
            this.businessRegForm.controls['fpoLinkName'].setValue("");
            this.businessRegForm.controls['farmLocation'].setValue("");
            this.businessRegForm.controls['primaryCropsGrown'].setValue("");
            this.businessRegForm.controls['commodityId'].setValue("");
            this.businessRegForm.controls['fpoName'].setValue("");
            this.businessRegForm.controls['fpoRegId'].setValue("");
        } else if (userType == 'Service Providers') {
            this.isServiceProvider = true;
            this.isFarmers = false;
            this.isFPO = false;
            this.isCommodityProcessors = false;
            this.businessRegForm.controls['fpoLinkName'].setValue("");
            this.businessRegForm.controls['farmLocation'].setValue("");
            this.businessRegForm.controls['primaryCropsGrown'].setValue("");
            this.businessRegForm.controls['fpoName'].setValue("");
            this.businessRegForm.controls['fpoRegistrationNumber'].setValue("");
            this.businessRegForm.controls['fpoCity'].setValue("");
            this.businessRegForm.controls['commodityId'].setValue("");
            this.businessRegForm.controls['fpoRegId'].setValue("");
        } else if (userType == 'Commodity Processors') {
            this.isCommodityProcessors = true;
            this.isServiceProvider = false;
            this.isFarmers = false;
            this.isFPO = false;
            this.businessRegForm.controls['fpoLinkName'].setValue("");
            this.businessRegForm.controls['farmLocation'].setValue("");
            this.businessRegForm.controls['primaryCropsGrown'].setValue("");
            this.businessRegForm.controls['fpoName'].setValue("");
            this.businessRegForm.controls['fpoRegistrationNumber'].setValue("");
            this.businessRegForm.controls['fpoCity'].setValue("");
            this.businessRegForm.controls['commodityId'].setValue("");
            this.businessRegForm.controls['fpoRegId'].setValue("");
        }
        this.userRegisterForm.controls['enrollmentType'].setValue(userType);
        console.log(userType)
    }

    FarmMapping(event: any): void {
        const value = event.target.value;
        const mapDetails = this.commodityDetails.find(map => map.commodityName == value);
        this.businessRegForm.controls['commodityId'].setValue(mapDetails.commodityId,);
    }

    selectFPO(event: any) {
        this.fpoName = event.target.value;
        if (this.farmLocation) {
            this.fpoLinkName = `${this.fpoName} - ${this.farmLocation}`;
            this.businessRegForm.controls['fpoLinkName'].setValue(this.fpoLinkName);
        }
    }

    selectCity(event: any, locationType: string) {
        if (locationType == 'city') {
            const district = event.target.value;
            this.userRegisterForm.controls['city'].setValue(district);
            console.log(district);
        } else if (locationType == 'Farm Location') {
            this.farmLocation = event.target.value;
            console.log(this.farmLocation);
            this.fpoLinkName = `${this.fpoName} - ${this.farmLocation}`;
            this.businessRegForm.controls['fpoLinkName'].setValue(this.fpoLinkName);
            console.log(this.fpoLinkName);
        } else if (locationType == 'FPO Location') {
            this.farmLocation = event.target.value;
        }
    }


    handleFileInput(event: Event): void {
        const files = (event.target as HTMLInputElement).files;
        if (files) {
            Array.from(files).forEach(file => this.convertToBase64(file));
        }
    }

    private convertToBase64(file: File): void {
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result as string;
            const base64Value = base64String.split(',')[1];
            // Push the file data to the verificationImageList FormArray
            const fileData = {
                imageData: base64Value,
                imageDescription: 'User register files', // Set as needed
                imageExtension: file.name.split('.').pop() || '',
                imageType: "VERIFICATION",
                imageFileName: file.name
            };
            console.log(fileData)

            this.addImageToForm(fileData);
        };
        reader.onerror = error => {
            console.error('Error reading file:', error);
        };
        reader.readAsDataURL(file);
    }

    private addImageToForm(fileData: { imageData: string; imageDescription: string; imageFileName: string; imageExtension: string; imageType: string }): void {
        const imageForm = new UntypedFormGroup({
            imageData: new UntypedFormControl(fileData.imageData),
            imageDescription: new UntypedFormControl(fileData.imageDescription),
            imageExtension: new UntypedFormControl(fileData.imageExtension),
            imageType: new UntypedFormControl(fileData.imageType),
            imageFileName: new UntypedFormControl(fileData.imageFileName)
        });
        (this.userRegisterForm.get('verificationImageList') as UntypedFormArray).push(imageForm);
    }

    saveUserDetails() {
        const addressList = [{
            city: this.userRegisterForm.controls['city'].value,
            pinCode: this.userRegisterForm.controls['pinCode'].value,
        }];
        this.userRegisterForm.controls['accountStatus'].setValue('ACTIVE');
        this.userRegisterForm.controls['addressDetails'].setValue(addressList);
        console.log('save form group::', this.userRegisterForm.value);
        console.log('save form group::', this.businessRegForm.value);
        this.spinner.show();
        this.appService.saveUserRegisterDetailsByAdmin(this.userRegisterForm.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (userData) => {
                    this.spinner.hide();
                    console.log('User Details::', userData);
                    this.userDetails = userData;
                    this.userRegistrationId = this.userDetails.userRegistrationId;
                    if (this.isFarmers) {
                        this.saveBusinessDetails();
                    } else {
                        this.saveServiceProviderBusinessDetails();
                    }
                },
                (error: any) => {
                    console.error("Error:", error);
                    this.spinner.hide();

                    const errorMessage = error?.error?.message || "Something went wrong, please try again";
                    this.toastr.error(errorMessage);
                }
            );
    }


    //save business details
    saveBusinessDetails() {
        const farmAddress = [{
            city: this.farmLocation,
        }];
        this.businessRegForm.controls['fpoLinkName'].setValue(this.fpoLinkName);
        this.businessRegForm.controls['farmAddress'].setValue(farmAddress);
        console.log(this.businessRegForm.value);
        this.spinner.show();
        this.appService.saveFarmerDetails(this.userRegistrationId, this.businessRegForm.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (registerData) => {
                    this.spinner.hide();
                    console.log('User Details::', registerData);
                    this.toastr.success(`Your details registered successfully`);
                    /*this.otpModal = true;
                    this.getRandomNumber();*/
                    this.userRegisterForm.reset({
                        userRegistrationId: "",
                        name: "",
                        countryCode: "91", // Retaining default value for countryCode
                        phoneNumber: "",
                        email: "",
                        enrollmentType: "Commodity Processors", // Retaining default value
                        accountStatus: "",
                        selfDescription: "",
                        city: "Madurai", // Retaining default value
                        pinCode: "",
                        createdBy: "",
                        createdAt: 0, // Defaulting numbers to 0
                        updatedBy: "",
                        updatedAt: 0,
                        farmerRole: "",
                        verificationImageList: [], // Empty array for FormArray
                        addressDetails: "",
                    });

                    this.businessRegForm.reset({
                        farmDetailsId: "",
                        primaryCropsGrown: "",
                        fpoLinkName: "",
                        fpoName: "",
                        fpoRegistrationNumber: "",
                        fpoCity: "",
                        farmLocation: "",
                        farmAddress: "",
                        commodityId: "",
                        fpoRegId: "",
                        gstNo: "",
                        businessLicenseNumber: "",
                        businessName: "",
                        businessAddress: "",
                        businessLocation: "",
                    });

                    // Reset serviceRegFrom with empty strings
                    this.serviceRegFrom.reset({
                        businessName: "",
                        typeOfService: "",
                    });
                },

                (error: any) => {
                    console.error("Error:", error);
                    this.spinner.hide();

                    const errorMessage = error?.error?.message || "Something went wrong, please try again";
                    this.toastr.error(errorMessage);
                }
            );
    }

    saveServiceProviderBusinessDetails() {
        const businessAddress = [{
            city: this.businessRegForm.controls['businessLocation'].value,
        }];
        this.businessRegForm.controls['businessAddress'].setValue(businessAddress);
        this.spinner.show();
        console.log("Save Details", this.businessRegForm.value)
        this.appService.saveServiceProviderBusinessDetails(this.userRegistrationId, this.businessRegForm.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((response: any) => {
                this.spinner.hide();
                console.log('Response from save:', response);
                this.toastr.success(`Your details registered successfully`);
                this.userRegisterForm.reset({
                    userRegistrationId: "",
                    name: "",
                    countryCode: "91", // Retaining default value for countryCode
                    phoneNumber: "",
                    email: "",
                    enrollmentType: "Commodity Processors", // Retaining default value
                    accountStatus: "",
                    selfDescription: "",
                    city: "Madurai", // Retaining default value
                    pinCode: "",
                    createdBy: "",
                    createdAt: 0, // Defaulting numbers to 0
                    updatedBy: "",
                    updatedAt: 0,
                    farmerRole: "",
                    verificationImageList: [], // Empty array for FormArray
                    addressDetails: "",
                });

                // Reset businessRegForm with empty strings
                this.businessRegForm.reset({
                    farmDetailsId: "",
                    primaryCropsGrown: "",
                    fpoLinkName: "",
                    fpoName: "",
                    fpoRegistrationNumber: "",
                    fpoCity: "",
                    farmLocation: "",
                    farmAddress: "",
                    commodityId: "",
                    fpoRegId: "",
                    gstNo: "",
                    businessLicenseNumber: "",
                    businessName: "",
                    businessAddress: "",
                    businessLocation: "",
                });

                // Reset serviceRegFrom with empty strings
                this.serviceRegFrom.reset({
                    businessName: "",
                    typeOfService: "",
                });
               /* window.location.reload();*/
               /* this.otpModal = true;
                this.getRandomNumber();*/
            }, (error: any) => {
                console.error("Error:", error);
                this.spinner.hide();
                const errorMessage = error?.error?.message || "Something went wrong, please try again";
                this.toastr.error(errorMessage);
            })
    }

    getRandomNumber() {
        this.randomNumber = Math.floor(Math.random() * 900) + 100;
    }

    popupClose() {
        this.otpModal = false;
    }

}