import { Component, OnInit, Renderer2 } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AppService } from "@services/app.service";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfigService } from "@/config.service";
import { Subject, takeUntil } from 'rxjs';
import { ProductOrders } from "@/model/orders/ProductOrders";
import { PostOrders } from "@/model/orders/PostOrders";
import { val, value } from "dom7";
import { UserDetails } from "@/model/user-details/UserDetails";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { BusinessDocumentDetails } from "@/model/business/BusinessDocumentDetails";
import { FarmerDetails } from "@/model/farmers/farmer-details";
import { CommodityDetails } from "@/model/commodity/CommodityDetails";
import { BusinessDetails } from "@/model/business/BusinessDetails";
import { BankList } from "assets/bank-list/bank-details";
import { DistrictDetails } from "assets/distrcts-list/district-details";
import { StateDetails } from "assets/state-list/state-details";

@Component({
    selector: 'app-business-details',
    templateUrl: './business-details.component.html',
    styleUrls: ['./business-details.component.scss']
})

export class BusinessDetailsComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    getAllRegistrationDetails: UserDetails[] = [];
    getUserNameDetails: UserDetails[] = [];
    getAllEnrollTypes: String[] = [];
    base64Images: string[] = [];
    getAllFarmsDetails: FarmerDetails[] = [];
    commodityDetails: CommodityDetails[] = [];
    getAllBusinessDetails: BusinessDetails[] = [];
    businessDetails: BusinessDetails;
    farmsDetails: FarmerDetails;
    readonly MAX_FILE_SIZE = 2 * 1024 * 1024;
    readonly MAX_IMAGE_COUNT = 6;
    userRegId: number = 0;
    ALLOWED_FORMATS = ['image/jpeg', 'image/png', 'application/pdf'];
    businessDocumentImage: BusinessDocumentDetails[] = [];
    fpoDetails: any[] = [];
    certificationsFiles: any[] = [];
    businessDocumentsFiles: any[] = [];
    showForms: boolean = false;
    onShowServiceProvider: boolean = false;
    onShowFPO: boolean = false;
    onShowFramerInput: boolean = false;
    onShowFoodProcessor: boolean = false;
    onShowCommodityProcessor: boolean = false;
    onShowTrader: boolean = false;
    onShowDistributor: boolean = false;
    vendorRegId: number = 0;
    farmsDetailsId: number = 0;
    businessId: number = 0;
    enRollType: string = "";
    userName: string = "";
    fpoRegId: number = 0;
    isUpdate: boolean = false;
    endPoint: string = "";
    bankNames: string[] = [];
    districtsList: string[] = [];
    stateList: string[] = [];
    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private configService: ConfigService
    ) {
    }

    newBusinessDetails = new UntypedFormGroup({
        businessId: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        businessName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        businessEmail: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        businessLicenseNumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        tradeLicenseNumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        serviceLicenseNumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        enrollmentType: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        contactNumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        serviceReach: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        availability: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pricingModel: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        description: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        latitude: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        longitude: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        sourceRegions: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        gstNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        businessStatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        distributionRegions: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        areaOfCoverage: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        typeOfService: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        countryCode: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        createdBy: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        businessAddress: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        businessDocumentDetails: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        bankDetails: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    })

    newFarmerDetails = new UntypedFormGroup({
        farmDetailsId: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        farmSize: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        typeOfUser: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        primaryCropsGrown: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        enrollmentType: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        contactNumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        description: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        latitude: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        longitude: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        farmsStatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        fpoName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        fpoLinkName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        fpoRegId: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        noOfMembers: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        fpoRegistrationNumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        countryCode: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        createdBy: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        createdAt: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        userRegId: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        farmAddress: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        bankDetails: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        farmerMapped: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    })

    addressDetailsForms = new UntypedFormGroup({
        streetName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        village: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        city: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        state: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        pinCode: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        userId: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        addressId: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    })

    bankDetailsForms = new UntypedFormGroup({
        bankName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        accountNumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        ifscCode: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        accountHolderName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        branchName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        userId: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        bankDetailsId: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    })

    public allTypeOfError = {
        farmSize: '',
        primaryCropsGrown: '',
        contactNumber: '',
        noOfMembers: '',
        fpoRegistrationNumber: '',
        latitude: '',
        longitude: '',
        fpoName: '',
        village: '',
        city: '',
        state: '',
        pinCode: '',
        bankName: '',
        accountNumber: '',
        ifscCode: '',
        accountHolderName: '',
        branchName: '',
        businessName: '',
        businessLicenseNumber: '',
        tradeLicenseNumber: '',
        serviceLicenseNumber: '',
        serviceReach: '',
        availability: '',
        sourceRegions: '',
        gstNo: '',
        areaOfCoverage: '',
        typeOfService: '',
        distributionRegions: '',
        pricingModel: '',
        description: '',
    }

    ngOnInit() {
        this.bankNames = BankList.banks;
        this.districtsList = DistrictDetails.districtName;
        this.stateList = StateDetails.statesAndUTs;
        this.vendorRegId = Number(localStorage.getItem('vendorRegId'));
        localStorage.setItem('userRegId', String(this.vendorRegId));
        this.enRollType = localStorage.getItem('userGetType');
        this.businessId = Number(localStorage.getItem('businessId'));
        this.farmsDetailsId = Number(localStorage.getItem('farmsId'));
        this.showForms = true;
        if (this.enRollType === 'FPO' || this.enRollType === 'Farmers') {
            this.newBusinessDetails.disable();
            if (this.farmsDetailsId == 0) {
                this.getAllCommodity();
                this.getAllFPODetails();
                this.isUpdate = false;
            } else {
                this.getAllCommodity();
                this.getAllFPODetails();
                this.getFarmsDetails();
                this.isUpdate = true;
            }
        } else {
            this.newFarmerDetails.disable();
            if (this.businessId == 0) {
                this.isUpdate = false;
            } else {
                this.getBusinessDetails();
                this.isUpdate = true;
            }
        }
        if (this.enRollType === 'Service Providers') {
            this.onShowServiceProvider = true;
            this.onShowFPO = false;
            this.onShowDistributor = false;
            this.onShowCommodityProcessor = false;
            this.onShowTrader = false;
            this.onShowFramerInput = false;
            this.onShowFoodProcessor = false;
        } else if (this.enRollType === 'FPO' || this.enRollType === 'Farmers') {
            this.onShowFPO = true;
            this.showForms = false;
            this.onShowDistributor = false;
            this.onShowCommodityProcessor = false;
            this.onShowTrader = false;
            this.onShowFramerInput = false;
            this.onShowFoodProcessor = false;
            this.onShowServiceProvider = false;
        } else if (this.enRollType === 'Traders') {
            this.onShowTrader = true;
            this.onShowServiceProvider = false;
            this.onShowFPO = false;
            this.onShowDistributor = false;
            this.onShowCommodityProcessor = false;
            this.onShowFramerInput = false;
            this.onShowFoodProcessor = false;
        } else if (this.enRollType === 'Distributors') {
            this.onShowDistributor = true;
            this.onShowServiceProvider = false;
            this.onShowFPO = false;
            this.onShowCommodityProcessor = false;
            this.onShowTrader = false;
            this.onShowFramerInput = false;
            this.onShowFoodProcessor = false;
        } else if (this.enRollType === 'Farmer Input') {
            this.onShowFramerInput = true;
            this.onShowServiceProvider = false;
            this.onShowFPO = false;
            this.onShowDistributor = false;
            this.onShowCommodityProcessor = false;
            this.onShowTrader = false;
            this.onShowFoodProcessor = false;
        } else if (this.enRollType === 'Food Processors') {
            this.onShowFoodProcessor = true;
            this.onShowServiceProvider = false;
            this.onShowFPO = false;
            this.onShowDistributor = false;
            this.onShowCommodityProcessor = false;
            this.onShowTrader = false;
            this.onShowFramerInput = false;
        } else if (this.enRollType === 'Commodity Processors') {
            this.onShowCommodityProcessor = true;
            this.onShowServiceProvider = false;
            this.onShowFPO = false;
            this.onShowDistributor = false;
            this.onShowTrader = false;
            this.onShowFramerInput = false;
            this.onShowFoodProcessor = false;
        }
    }

    onSubmit() {
        this.allTypeOfError.farmSize = "";
        this.allTypeOfError.primaryCropsGrown = "";
        this.allTypeOfError.contactNumber = "";
        this.allTypeOfError.noOfMembers = "";
        this.allTypeOfError.fpoRegistrationNumber = "";
        this.allTypeOfError.latitude = "";
        this.allTypeOfError.longitude = "";
        this.allTypeOfError.fpoName = "";
        this.allTypeOfError.village = "";
        this.allTypeOfError.city = "";
        this.allTypeOfError.state = "";
        this.allTypeOfError.pinCode = "";
        this.allTypeOfError.bankName = "";
        this.allTypeOfError.accountNumber = "";
        this.allTypeOfError.ifscCode = "";
        this.allTypeOfError.accountHolderName = "";
        this.allTypeOfError.branchName = "";
        this.allTypeOfError.businessName = "";
        this.allTypeOfError.businessLicenseNumber = "";
        this.allTypeOfError.tradeLicenseNumber = "";
        this.allTypeOfError.serviceLicenseNumber = "";
        this.allTypeOfError.serviceReach = "";
        this.allTypeOfError.availability = "";
        this.allTypeOfError.sourceRegions = "";
        this.allTypeOfError.gstNo = "";
        this.allTypeOfError.areaOfCoverage = "";
        this.allTypeOfError.typeOfService = "";
        this.allTypeOfError.distributionRegions = "";
        this.allTypeOfError.pricingModel = "";
        this.allTypeOfError.description = "";
        let hasError = false;

        if (this.enRollType == "Farmers") {
            if (this.newFarmerDetails.get('farmSize')?.invalid) {
                this.allTypeOfError.farmSize = "* Farm Size is required";
                this.newFarmerDetails.markAsTouched();
                hasError = true;
            }
        }

        if (this.enRollType == "Farmers") {
            if (this.newFarmerDetails.get('primaryCropsGrown')?.invalid) {
                this.allTypeOfError.primaryCropsGrown = "*Primary Crops Grown is required";
                this.newFarmerDetails.markAsTouched();
                hasError = true;
            }
        }

        if (this.newFarmerDetails.get('noOfMembers')?.invalid && this.enRollType == 'FPO') {
            this.allTypeOfError.noOfMembers = "* Number of Members is required";
            this.newFarmerDetails.markAsTouched();
            hasError = true;
        }

        if (this.newFarmerDetails.get('fpoRegistrationNumber')?.invalid && this.enRollType == 'FPO') {
            this.allTypeOfError.fpoRegistrationNumber = "* FPO Registration Number is required";
            this.newFarmerDetails.markAsTouched();
            hasError = true;
        }

        if (this.newFarmerDetails.get('contactNumber')?.invalid) {
            this.allTypeOfError.contactNumber = "* Contact Number is required";
            this.newFarmerDetails.markAsTouched();
            hasError = true;
        }

        if (this.newFarmerDetails.get('latitude')?.invalid) {
            this.allTypeOfError.latitude = "* Latitude is required";
            this.newFarmerDetails.markAsTouched();
            hasError = true;
        }

        if (this.newFarmerDetails.get('longitude')?.invalid) {
            this.allTypeOfError.longitude = "* Longitude is required";
            this.newFarmerDetails.markAsTouched();
            hasError = true;
        }

        if (this.newFarmerDetails.get('fpoName').invalid) {
            this.allTypeOfError.fpoName = "* FPO Name is required";
            this.newFarmerDetails.markAsTouched();
            hasError = true;
        }

        if (this.addressDetailsForms.get('village')?.invalid) {
            this.allTypeOfError.village = "* Village / Town is required";
            this.addressDetailsForms.markAsTouched();
            hasError = true;
        }

        if (this.addressDetailsForms.get('city')?.invalid) {
            this.allTypeOfError.city = "* City is required";
            this.addressDetailsForms.markAsTouched();
            hasError = true;
        }

        if (this.addressDetailsForms.get('state')?.invalid) {
            this.allTypeOfError.state = "* State is required";
            this.addressDetailsForms.markAllAsTouched();
            hasError = true;
        }

        if (this.addressDetailsForms.get('pinCode')?.invalid) {
            this.allTypeOfError.pinCode = "* Pin Code is required";
            this.addressDetailsForms.markAsTouched();
            hasError = true;
        }

        if (this.bankDetailsForms.get('bankName')?.invalid) {
            this.allTypeOfError.bankName = "* Bank Name is required";
            this.bankDetailsForms.markAsTouched();
            hasError = true;
        }

        if (this.bankDetailsForms.get('accountNumber')?.invalid) {
            this.allTypeOfError.accountNumber = "* Account Number is required";
            this.bankDetailsForms.markAsTouched();
            hasError = true;
        }

        if (this.bankDetailsForms.get('ifscCode')?.invalid) {
            this.allTypeOfError.ifscCode = "* IFSC Code is required";
            this.bankDetailsForms.markAsTouched();
            hasError = true;
        }

        if (this.bankDetailsForms.get('accountHolderName')?.invalid) {
            this.allTypeOfError.accountHolderName = "* Account Holder Name is required";
            this.bankDetailsForms.markAsTouched();
            hasError = true;
        }

        if (this.bankDetailsForms.get('branchName')?.invalid) {
            this.allTypeOfError.branchName = "* Branch Name is required";
            this.bankDetailsForms.markAsTouched();
            hasError = true;
        }

        if (this.newBusinessDetails.get('contactNumber')?.invalid) {
            this.allTypeOfError.contactNumber = "* Contact Number is required";
            this.newBusinessDetails.markAsTouched();
            hasError = true;
        }

        if (this.newBusinessDetails.get('businessName')?.invalid) {
            this.allTypeOfError.businessName = "* Business Name is required";
            this.newBusinessDetails.markAsTouched();
            hasError = true;
        }

        if (this.enRollType == 'Service Providers') {
            if (this.newBusinessDetails.get('typeOfService')?.invalid) {
                this.allTypeOfError.typeOfService = "* Type of Service is required";
                this.newBusinessDetails.markAsTouched();
                hasError = true;
            }

            if (this.newBusinessDetails.get('serviceLicenseNumber')?.invalid) {
                this.allTypeOfError.serviceLicenseNumber = "* Service License Number is required";
                this.newBusinessDetails.markAsTouched();
                hasError = true;
            }

            if (this.newBusinessDetails.get('availability')?.invalid) {
                this.allTypeOfError.availability = "* Availability is required";
                this.newBusinessDetails.markAsTouched();
                hasError = true;
            }


            if (this.newBusinessDetails.get('pricingModel')?.invalid) {
                this.allTypeOfError.pricingModel = "* Pricing Model is required";
                this.newBusinessDetails.markAsTouched();
                hasError = true;
            }

        }

        if (this.enRollType == 'Farmer Input' || this.enRollType == 'Food Processors' || this.enRollType == 'Commodity Processors') {
            if (this.newBusinessDetails.get('businessLicenseNumber')?.invalid) {
                this.allTypeOfError.businessLicenseNumber = "* Business License Number is required"
                this.newBusinessDetails.markAllAsTouched();
                hasError = true;
            }
        }

        if (this.enRollType == 'Traders' || this.enRollType == 'Distributors') {
            if (this.newBusinessDetails.get('tradeLicenseNumber')?.invalid) {
                this.allTypeOfError.tradeLicenseNumber = "* Trader License Number is required"
                this.newBusinessDetails.markAllAsTouched();
                hasError = true;
            }
        }

        if (this.newBusinessDetails.get('gstNo')?.invalid) {
            this.allTypeOfError.gstNo = "* Gst Number is required"
            this.newBusinessDetails.markAllAsTouched();
            hasError = true;
        }

        if (this.enRollType == 'Service Providers' || this.enRollType == 'Farmer Input') {
            if (this.newBusinessDetails.get('serviceReach')?.invalid) {
                this.allTypeOfError.serviceReach = "*Service Reach/Area Covered is required"
                this.newBusinessDetails.markAllAsTouched();
                hasError = true;
            }
        }

        if (this.enRollType == 'Traders' || this.enRollType == 'Distributors' || this.enRollType == 'Food Processors'
            || this.enRollType == 'Commodity Processors') {
            if (this.newBusinessDetails.get('sourceRegions')?.invalid) {
                this.allTypeOfError.sourceRegions = "*Source Regions is required"
                this.newBusinessDetails.markAllAsTouched();
                hasError = true;
            }
        }

        if (this.enRollType == 'Traders') {
            if (this.newBusinessDetails.get('distributionRegions')?.invalid) {
                this.allTypeOfError.distributionRegions = "*Distribution Regions is required"
                this.newBusinessDetails.markAllAsTouched();
                hasError = true;
            }
        }

        if (this.newBusinessDetails.get('latitude')?.invalid) {
            this.allTypeOfError.latitude = "* Latitude is required";
            this.newBusinessDetails.markAsTouched();
            hasError = true;
        }

        if (this.newBusinessDetails.get('longitude')?.invalid) {
            this.allTypeOfError.longitude = "* Longitude is required";
            this.newBusinessDetails.markAsTouched();
            hasError = true;
        }

        if (this.newBusinessDetails.get('description')?.invalid) {
            this.allTypeOfError.description = "* Description is required";
            this.newBusinessDetails.markAsTouched();
            hasError = true;
        }

        if (!hasError) {
            let addressId: number = 0;
            let bankId: number = 0;
            if (this.isUpdate) {
                if (this.enRollType === 'Farmers' || this.enRollType === 'FPO') {
                    if (this.farmsDetails.farmAddress.length > 0) {
                        addressId = this.farmsDetails.farmAddress[0].addressId;
                    }
                    if (this.farmsDetails.bankDetails.length > 0) {
                        bankId = this.farmsDetails.bankDetails[0].bankDetailsId;
                    }
                } else {
                    if (this.businessDetails.businessAddress.length > 0) {
                        addressId = this.businessDetails.businessAddress[0].addressId;
                    }
                    if (this.businessDetails.bankDetails.length > 0) {
                        bankId = this.businessDetails.bankDetails[0].bankDetailsId;
                    }
                }
            }

            const addressDetails = [{
                village: this.addressDetailsForms.get('village')?.value,
                city: this.addressDetailsForms.get('city')?.value,
                state: this.addressDetailsForms.get('state')?.value,
                pinCode: this.addressDetailsForms.get('pinCode')?.value,
                userId: this.vendorRegId,
                addressId: addressId
            }]
            const bankDetails = [{
                bankName: this.bankDetailsForms.get('bankName')?.value,
                accountNumber: this.bankDetailsForms.get('accountNumber')?.value,
                ifscCode: this.bankDetailsForms.get('ifscCode')?.value,
                accountHolderName: this.bankDetailsForms.get('accountHolderName')?.value,
                branchName: this.bankDetailsForms.get('branchName')?.value,
                userId: this.vendorRegId,
                bankDetailsId: bankId
            }]
            console.log("save details", this.newFarmerDetails.value);
            console.log("save details", this.newBusinessDetails.value)
            if (this.isUpdate) {
                if (this.enRollType == 'FPO' || this.enRollType == 'Farmers') {
                    this.newFarmerDetails.controls['farmAddress'].setValue(addressDetails);
                    this.newFarmerDetails.controls['bankDetails'].setValue(bankDetails);
                    this.newFarmerDetails.controls['enrollmentType'].setValue(this.enRollType);
                    this.updateFarmsDetails();
                } else {
                    this.newBusinessDetails.controls['businessAddress'].setValue(addressDetails);
                    this.newBusinessDetails.controls['bankDetails'].setValue(bankDetails);
                    this.newBusinessDetails.controls['enrollmentType'].setValue(this.enRollType);
                    this.updateBusinessDetails();
                }
            } else {
                if (this.enRollType == 'FPO' || this.enRollType == 'Farmers') {
                    this.newFarmerDetails.controls['farmAddress'].setValue(addressDetails);
                    this.newFarmerDetails.controls['bankDetails'].setValue(bankDetails);
                    this.newFarmerDetails.controls['enrollmentType'].setValue(this.enRollType);
                    this.saveFarmerDetails();
                } else {
                    this.newBusinessDetails.controls['businessDocumentDetails'].setValue(this.businessDocumentsFiles);
                    this.newBusinessDetails.controls['businessAddress'].setValue(addressDetails);
                    this.newBusinessDetails.controls['bankDetails'].setValue(bankDetails);
                    this.newBusinessDetails.controls['enrollmentType'].setValue(this.enRollType);
                    this.saveBusinessDetails();
                }
            }
        }
    }


    cancel() {
        this.toastr.info('You canceled the update of business details');
        this.router.navigate(['/main/verify-profile']);
    }


    getFarmsDetails() {
        this.spinner.show();
        this.appService.getFarmerDetailsById(this.vendorRegId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.spinner.hide();
                console.log('Farm details::', data);
                this.getAllFarmsDetails = data;
                this.farmsDetails = this.getAllFarmsDetails.find(item => item.farmDetailsId === this.farmsDetailsId);
                console.log(this.farmsDetails);
                this.bankDetailsForms.patchValue(this.farmsDetails?.bankDetails[0]);
                this.newFarmerDetails.patchValue(this.farmsDetails);
                this.addressDetailsForms.patchValue(this.farmsDetails?.farmAddress[0]);
                console.log(this.farmsDetails?.farmAddress[0]?.city);
            }, (error: any) => {
                this.spinner.hide();
                const errorMessage = error?.error?.message || "Something went wrong, please try again";
                this.toastr.error(errorMessage);
            })
    }

    getBusinessDetails() {
        this.spinner.show();
        this.appService.getBusinessDetailsById(this.businessId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.spinner.hide();
                console.log('Business details::', data);
                // this.getAllBusinessDetails = data;
                this.businessDetails = data;
                console.log(this.businessDetails);
                this.bankDetailsForms.patchValue(this.businessDetails?.bankDetails[0]);
                this.newBusinessDetails.patchValue(this.businessDetails);
                this.addressDetailsForms.patchValue(this.businessDetails?.businessAddress[0]);
                this.businessDocumentsFiles = this.businessDetails?.businessDocumentDetails;
                this.endPoint = this.configService.getConfig().END_POINT;
                console.log(this.businessDetails?.businessAddress[0]?.city);
            }, (error: any) => {
                this.spinner.hide();
                const errorMessage = error?.error?.message || "Something went wrong, please try again";
                this.toastr.error(errorMessage);
            })
    }

    getAllCommodity() {
        this.spinner.show();
        this.appService.getCommodityList()
            .pipe(takeUntil(this.destroy$))
            .subscribe((commodity) => {
                this.spinner.hide();
                this.commodityDetails = commodity;
                console.log('commodity list::', this.commodityDetails);
            }, (error: any) => {
                console.error("Error:", error);
                this.spinner.hide();
                const errorMessage = error?.error?.message || "Something went wrong, please try again";
                this.toastr.error(errorMessage);
            }
            );
    }

    getAllFPODetails() {
        this.spinner.show();
        this.appService.getAllFPODetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.spinner.hide();
                this.fpoDetails = data;
            }, (error: any) => {
                console.error("Error:", error);
                this.spinner.hide();
                const errorMessage = error?.error?.message || "Something went wrong, please try again";
                this.toastr.error(errorMessage);
            }
            );
    }

    getRegistrationDetails() {
        this.spinner.show();
        this.appService.getAllUserRegisteredDetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.spinner.hide();
                console.log('Registration details::', data);
                this.getAllRegistrationDetails = data;
                const enrollmentType = new Set(this.getAllRegistrationDetails.map(item => item.enrollmentType));
                this.getAllEnrollTypes = Array.from(enrollmentType).filter(item => item != "");
                this.fpoDetails = this.getAllRegistrationDetails.filter(item => item.enrollmentType === 'FPO')
                console.log('Registration details::', this.fpoDetails)
            }, (error: any) => {
                this.spinner.hide();
                const errorMessage = error?.error?.message || "Something went wrong, please try again";
                this.toastr.error(errorMessage);
            })
    }

    onShowEnrollDetails(event: any) {
        const Values = event.target.value;
        this.enRollType = Values;
        this.newBusinessDetails.controls['enrollmentType'].setValue(Values);
        this.getUserNameDetails = this.getAllRegistrationDetails.filter(item => item.enrollmentType === Values);

    }

    onShowUserId(event: any) {
        console.log('event', event.target.value);
        const Values = event.target.value;
        this.userRegId = Number(Values);
        const UserDetails = this.getUserNameDetails.find(item => item.userRegistrationId === this.userRegId);
        this.userName = UserDetails?.name;
    }

    onFileSelected(event: Event, type: string): void {
        const input = event.target as HTMLInputElement;

        if (input.files && input.files.length > 0) {
            const file = input.files[0];

            // Validate file type
            if (!this.ALLOWED_FORMATS.includes(file.type)) {
                this.toastr.error("Only JPG, PNG, and PDF files are allowed.");
                return;
            }

            const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
            if (file.size > maxSizeInBytes) {
                this.toastr.error("File size must be below 2MB.");
                return;
            }
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const base64String = e.target.result as string;
                const base64Value = base64String.split(',')[1];

                const fileData = {
                    documentPath: base64String,
                    documentData: base64Value,
                    referenceId: 0,
                    documentType: "DOCUMENT",
                    documentFileName: file.name,
                    businessDocumentId: 0,
                    documentDescription: type,
                    createdAt: 0,
                    userId: 0,
                    documentExtension: file.type.split('/')[1]
                };

                console.log("test", fileData);
                // Push file data to the respective array based on the input type
                if (type === 'Certifications') {
                    this.businessDocumentsFiles.push(fileData);
                } else if (type === 'Business Documents') {
                    this.businessDocumentsFiles.push(fileData);
                }
                console.log("File data added:", this.businessDocumentsFiles);
            };
            reader.readAsDataURL(file);
            input.value = ''; // Reset the file input
        }
    }

    // Method to view the file
    viewFile(file: any): void {
        const newWindow = window.open();
        newWindow?.document.write(`<iframe src="${file.documentPath}" width="100%" height="100%"></iframe>`);
    }

    // Method to remove file from the list based on the type
    removeFile(index: number, type: string): void {
        this.spinner.show();
        if (type === 'Certifications') {
            this.businessDocumentsFiles.splice(index, 1);
            this.spinner.hide();
        } else if (type === 'Business Documents') {
            this.spinner.hide();
            this.businessDocumentsFiles.splice(index, 1);
        }
    }

    // only number accept for input 
    OnlyNumbers($event) {
        let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
        let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft'];
        if (specialKeys.indexOf($event.key) !== -1) {
            return;
        } else {
            if (regex.test($event.key)) {
                return true;
            } else {
                return false;
            }
        }
    }

    saveBusinessDetails() {
        this.spinner.show();
        console.log("Save Business Details", this.newBusinessDetails.value)
        this.appService.saveServiceProviderBusinessDetails(this.vendorRegId, this.newBusinessDetails.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((response: any) => {
                this.spinner.hide();
                console.log('Response from save:', response);
                this.toastr.success("Business Details Saved Successfully.");

                localStorage.removeItem('vendorRegId');
                localStorage.removeItem('farmsId');
                localStorage.removeItem('userGetType');
                localStorage.removeItem('businessId');
            }, (error: any) => {
                this.spinner.hide();
                const errorMessage = error?.error?.message || "Something went wrong, please try again";
                this.toastr.error(errorMessage);
            })
    }

    saveFarmerDetails() {
        this.spinner.show();
        console.log("Save Farms Details", this.newFarmerDetails.value);
        this.appService.saveFarmerDetails(this.vendorRegId, this.newFarmerDetails.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((response: any) => {
                this.spinner.hide();
                console.log('Response from save:', response);
                this.toastr.success("Farms Details Save Successfully.");
                this.router.navigate(['/main/user-management']);
                localStorage.removeItem('vendorRegId');
                localStorage.removeItem('farmsId');
                localStorage.removeItem('userGetType');
                localStorage.removeItem('businessId');
            }, (error: any) => {
                this.spinner.hide();
                const errorMessage = error?.error?.message || "Something went wrong, please try again";
                this.toastr.error(errorMessage);
            })
    }


    updateBusinessDetails() {
        this.spinner.show();
        console.log("Update Business Details", this.newBusinessDetails.value)
        this.appService.updateBusinessDetailsByAdmin(this.vendorRegId, this.newBusinessDetails.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((response: any) => {
                console.log('Response from save:', response);
                this.updateBusinessImage();
            }, (error: any) => {
                this.spinner.hide();
                const errorMessage = error?.error?.message || "Something went wrong, please try again";
                this.toastr.error(errorMessage);
            })
    }

    updateFarmsDetails() {
        this.spinner.show();
        console.log("updateFarmsDetails", this.newFarmerDetails.value);
        this.appService.updateFarmerDetailsByAdmin(this.vendorRegId, this.newFarmerDetails.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((response: any) => {
                this.spinner.hide();
                console.log('Response from save:', response);
                this.toastr.success("Farms Details Updated Successfully.");
                this.router.navigate(['/main/user-management']);
                localStorage.removeItem('vendorRegId');
                localStorage.removeItem('farmsId');
                localStorage.removeItem('userGetType');
                localStorage.removeItem('businessId');
            }, (error: any) => {
                this.spinner.hide();
                const errorMessage = error?.error?.message || "Something went wrong, please try again";
                this.toastr.error(errorMessage);
            })
    }

    onShowFPODetails(event: any) {
        const Values = event.target.value;
        const filterValue = this.fpoDetails.find((data) => data.name == Values);
        this.fpoRegId = filterValue.userRegistrationId;
        this.newFarmerDetails.controls['fpoRegId'].setValue(this.fpoRegId);
    }

    ngOnDestroy(): void {
        localStorage.removeItem('vendorRegId');
        localStorage.removeItem('farmsId');
        localStorage.removeItem('userGetType');
        localStorage.removeItem('businessId');
    }


    deleteBusinessFile(id: number): void {
        this.spinner.show();
        this.appService.deleteBusinessDetailsImages(id)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.spinner.hide();
                this.toastr.success('File removed successfully');
                this.getBusinessDetails();
            }, (error: any) => {
                this.spinner.hide();
                const errorMessage = error?.error?.message || "Something went wrong, please try again";
                this.toastr.error(errorMessage);
            })
    }

    updateBusinessImage() {
        this.spinner.show();
        const formData = this.businessDocumentsFiles.map((data) => {
            if (data.businessDocumentId === 0) {
                return {
                    ...data,
                    documentPath: ""
                };
            }
            return data;
        });

        this.appService.updateAdminBusinessDetailsImages(this.businessId, this.vendorRegId, formData)
            .pipe(takeUntil(this.destroy$))
            .subscribe((response: any) => {
                this.spinner.hide();
                this.toastr.success("Business Details Update Successfully.");
                this.router.navigate(['/main/verify-profile']);
                localStorage.removeItem('vendorRegId');
                localStorage.removeItem('farmsId');
                localStorage.removeItem('userGetType');
                localStorage.removeItem('businessId');
            }, (error: any) => {
                this.spinner.hide();
                const errorMessage = error?.error?.message || "Something went wrong, please try again";
                this.toastr.error(errorMessage);
            })
    }
}