<section class="content">
    <div class="container-fluid ml-lg-3 ml-2">
        <div class="row">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-12 mt-3">
                    <div class="form-floating">
                        <select class="form-select" id="floatingSelect"
                                aria-label="Floating label select example" (change)="userFilter($event, 'userType')">
                            <option selected>Select User Type</option>
                            <option value="All">All</option>
                            <option *ngFor="let type of this.userType">{{ type }}</option>
                        </select>
                        <label for="floatingSelect">User Type</label>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12 mt-3">
                    <div class="form-floating">
                        <select class="form-select"
                                aria-label="Floating label select example" (change)="userFilter($event, 'date')">
                            <option selected disabled>Select Date</option>
                            <option value="All">All</option>
                            <option *ngFor="let date of this.date">{{ date }}</option>
                        </select>
                        <label for="floatingSelect">Date</label>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12 mt-3">
                    <div class="form-floating">
                        <select class="form-select"
                                aria-label="Floating label select example" (change)="userFilter($event, 'location')">
                            <option selected disabled>Select User Location</option>
                            <option value="All">All</option>
                            <option *ngFor="let location of this.userLocation" class="text-capitalize">{{ location }}</option>
                        </select>
                        <label for="floatingSelect">Location</label>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12 mt-3">
                    <div class="form-floating">
                        <input type="search" class="form-control" placeholder="search"
                               [(ngModel)]="searchText">
                        <label class="text-gray">Search</label>
                    </div>
                </div>
            </div>
        <!--<div class="content-card mt-4 mb-4">
            <mat-card class="items" *ngFor="let user of tableUserDetailsList | filter: searchText;">
                <div class="d-flex flex-column h-100">
                    <mat-card-header>
                        <div mat-card-avatar class="example-header-image">
                            <h5 class="icon-circle">
                                <h5 class="fw-bolder text-uppercase text-center text-white mt-2">
                                    {{ user.name ? user.name.charAt(0).toUpperCase() : '' }}
                                </h5>
                            </h5>
                        </div>
                        <mat-card-title class="fw-bold text-capitalize">
                            <h6>{{ user.name }}</h6>
                        </mat-card-title>
                        <mat-card-subtitle class="fw-medium text-black text-capitalize">
                            {{ user.enrollmentType }}
                        </mat-card-subtitle>
                    </mat-card-header>

                    <img mat-card-image class="flex-grow-1 object-fit-contain"
                         *ngIf="user.verificationImageList?.length > 0 && user.verificationImageList[0].imageId"
                         [src]="endPoint + '/api/image-details/get/' + user.verificationImageList[0].imageId"
                         alt="image" loading="lazy">

                    <mat-card-content class="flex-grow-1">
                        <h6 class="mb-1">{{ user.phoneNumber }}</h6>
                        <p>{{ user.email }}</p>
                    </mat-card-content>

                    <mat-card-actions class="text-end">
                        <button class="btn-inactive mr-md-4 mt-1" (click)="viewProfile(user.userRegistrationId)">View</button>
                        <span [ngClass]="{'badge-active': user.accountStatus === 'ACTIVE', 'badge-inactive': user.accountStatus !== 'ACTIVE'}"
                              class="text-white mt-1">{{ user.accountStatus }}</span>
                    </mat-card-actions>
                </div>
            </mat-card>
        </div>-->
            <div class="container-fluid mt-3">
                <div class="table-responsive table-style table-bordered">
                    <table class="table table-green table-head-fixed">
                        <thead class="">
                        <tr>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Phone Number</th>
                            <th>Email</th>
                            <th>User Type</th>
                            <th>City</th>
                            <th>Trail End Date</th>
                            <th>Status</th>
                            <th>View</th>
                        </tr>
                        </thead>
                        <tbody class="text-center" *ngIf="this.tableUserDetailsList.length > 0; else noDataFound">
                        <tr *ngFor="let user of this.tableUserDetailsList | filter: searchText; let i = index;">
                            <td>{{ i + 1 }}</td>
                            <td>{{ user.name || '-' }}</td>
                            <td>{{ user.phoneNumber || '-' }}</td>
                            <td>{{ user.email || '-' }}</td>
                            <td>{{ user.enrollmentType || '-' }}</td>
                            <td>{{ user?.userAddress[0]?.city || '-' }}</td>
                            <td>{{ user.trialEndDate | date: 'dd-MM-yyyy' || '-' }}</td>
                            <td>
                                 <span class="badge"
                                       [ngClass]="{'badge-gradient-green': user.accountStatus === 'ACTIVE','badge-gradient-red':user.accountStatus !== 'ACTIVE'}">{{ user.accountStatus }}</span>
                               <!-- <span [ngClass]="{'badge-active': user.accountStatus === 'ACTIVE', 'badge-inactive': user.accountStatus !== 'ACTIVE'}"
                                      class="text-white mt-1">{{ user.accountStatus }}</span>-->
                            </td>
                            <td><!-- <button class="btn-inactive mr-md-4 mt-1" (click)="viewProfile(user.userRegistrationId)">View</button>-->
                                <i class="fas fa-eye text-black" (click)="viewProfile(user.userRegistrationId)" type="button"></i>
                            </td>
                        </tr>
                        </tbody>
                        <ng-template #noDataFound>
                            <tr>
                                <td colspan="12" class="text-center text-danger">Data Not Found</td>
                            </tr>
                        </ng-template>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" type="jelly-box">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>