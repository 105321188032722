<div class="card card-outline card-primary">
  <div class="card-header text-center">
    <a [routerLink]="['/']" class="h1"><b>Admin</b>LTE</a>
  </div>
  <div class="card-body">
    <p class="login-box-msg">Register a new membership</p>

    <form (ngSubmit)="registerByAuth()" [formGroup]="registerForm">
      <div class="input-group mb-3">
        <input
          class="form-control"
          formControlName="email"
          placeholder="Email"
          type="email"
        />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-envelope"></span>
          </div>
        </div>
      </div>
      <div class="input-group mb-3">
        <input
          class="form-control"
          formControlName="password"
          placeholder="Password"
          type="password"
        />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-lock"></span>
          </div>
        </div>
      </div>
      <div class="input-group mb-3">
        <input
          class="form-control"
          formControlName="retypePassword"
          placeholder="Retype password"
          type="password"
        />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-lock"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-7">
          <div class="icheck-primary">
            <input
              id="agreeTerms"
              name="terms"
              type="checkbox"
              value="agree"
            />
            <label for="agreeTerms">
              I agree to the <a href="#">terms</a>
            </label>
          </div>
        </div>
        <!-- /.col -->
        <div class="col-5">
          <pf-button
            [block]="true"
            [disabled]="isFacebookLoading || isGoogleLoading"
            [loading]="isAuthLoading"
            [type]="'submit'"
          >
            Register
          </pf-button>
        </div>
        <!-- /.col -->
      </div>
    </form>

    <div class="social-auth-links text-center">
      <pf-button
        (click)="registerByFacebook()"
        [block]="true"
        [disabled]="isAuthLoading || isGoogleLoading"
        [loading]="isFacebookLoading"
        class="mb-2"
      >
        <i class="fab fa-facebook mr-2"></i>
        Sign up using Facebook
      </pf-button>
      <pf-button
        (click)="registerByGoogle()"
        [block]="true"
        [disabled]="isAuthLoading || isFacebookLoading"
        [loading]="isGoogleLoading"
        theme="danger"
      >
        <i class="fab fa-google mr-2"></i>
        Sign up using Google+
      </pf-button>
    </div>

    <a [routerLink]="['/login']" class="text-center">
      I already have a membership</a
    >
  </div>
</div>
